// source: marketing_campaign.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.grpc.api.v1.MarketingCampaign', null, global);
goog.exportSymbol('proto.grpc.api.v1.MarketingCampaign.AudienceStats', null, global);
goog.exportSymbol('proto.grpc.api.v1.MarketingCampaign.ConversionStats', null, global);
goog.exportSymbol('proto.grpc.api.v1.MarketingCampaign.DeliveryStats', null, global);
goog.exportSymbol('proto.grpc.api.v1.MarketingCampaign.EngagementStats', null, global);
goog.exportSymbol('proto.grpc.api.v1.MarketingCampaign.PublishStats', null, global);
goog.exportSymbol('proto.grpc.api.v1.MarketingCampaign.Status', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.MarketingCampaign = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.MarketingCampaign, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.MarketingCampaign.displayName = 'proto.grpc.api.v1.MarketingCampaign';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.MarketingCampaign.AudienceStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.MarketingCampaign.AudienceStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.MarketingCampaign.AudienceStats.displayName = 'proto.grpc.api.v1.MarketingCampaign.AudienceStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.MarketingCampaign.PublishStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.MarketingCampaign.PublishStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.MarketingCampaign.PublishStats.displayName = 'proto.grpc.api.v1.MarketingCampaign.PublishStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.MarketingCampaign.DeliveryStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.MarketingCampaign.DeliveryStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.MarketingCampaign.DeliveryStats.displayName = 'proto.grpc.api.v1.MarketingCampaign.DeliveryStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.MarketingCampaign.EngagementStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.MarketingCampaign.EngagementStats.displayName = 'proto.grpc.api.v1.MarketingCampaign.EngagementStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.MarketingCampaign.ConversionStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.MarketingCampaign.ConversionStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.MarketingCampaign.ConversionStats.displayName = 'proto.grpc.api.v1.MarketingCampaign.ConversionStats';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.MarketingCampaign.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.MarketingCampaign} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaign.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    locale: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contactFilterId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    contactFilterName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    contactFilterData: jspb.Message.getFieldWithDefault(msg, 7, ""),
    templateName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    templateDesign: jspb.Message.getFieldWithDefault(msg, 9, ""),
    templateHtml: jspb.Message.getFieldWithDefault(msg, 10, ""),
    templatePlaintext: jspb.Message.getFieldWithDefault(msg, 11, ""),
    templatePreview: jspb.Message.getFieldWithDefault(msg, 12, ""),
    previewText: jspb.Message.getFieldWithDefault(msg, 13, ""),
    status: jspb.Message.getFieldWithDefault(msg, 14, 0),
    dtCreate: jspb.Message.getFieldWithDefault(msg, 15, 0),
    dtUpdate: jspb.Message.getFieldWithDefault(msg, 16, 0),
    dtSend: jspb.Message.getFieldWithDefault(msg, 17, 0),
    dtProcessed: jspb.Message.getFieldWithDefault(msg, 18, 0),
    isArchived: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    isDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    audienceStats: (f = msg.getAudienceStats()) && proto.grpc.api.v1.MarketingCampaign.AudienceStats.toObject(includeInstance, f),
    publishStats: (f = msg.getPublishStats()) && proto.grpc.api.v1.MarketingCampaign.PublishStats.toObject(includeInstance, f),
    deliveryStats: (f = msg.getDeliveryStats()) && proto.grpc.api.v1.MarketingCampaign.DeliveryStats.toObject(includeInstance, f),
    engagementStats: (f = msg.getEngagementStats()) && proto.grpc.api.v1.MarketingCampaign.EngagementStats.toObject(includeInstance, f),
    conversionStats: (f = msg.getConversionStats()) && proto.grpc.api.v1.MarketingCampaign.ConversionStats.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.MarketingCampaign}
 */
proto.grpc.api.v1.MarketingCampaign.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.MarketingCampaign;
  return proto.grpc.api.v1.MarketingCampaign.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.MarketingCampaign} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.MarketingCampaign}
 */
proto.grpc.api.v1.MarketingCampaign.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocale(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContactFilterId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactFilterName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactFilterData(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateDesign(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateHtml(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplatePlaintext(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplatePreview(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreviewText(value);
      break;
    case 14:
      var value = /** @type {!proto.grpc.api.v1.MarketingCampaign.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDtCreate(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDtUpdate(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDtSend(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDtProcessed(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsArchived(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeleted(value);
      break;
    case 21:
      var value = new proto.grpc.api.v1.MarketingCampaign.AudienceStats;
      reader.readMessage(value,proto.grpc.api.v1.MarketingCampaign.AudienceStats.deserializeBinaryFromReader);
      msg.setAudienceStats(value);
      break;
    case 22:
      var value = new proto.grpc.api.v1.MarketingCampaign.PublishStats;
      reader.readMessage(value,proto.grpc.api.v1.MarketingCampaign.PublishStats.deserializeBinaryFromReader);
      msg.setPublishStats(value);
      break;
    case 23:
      var value = new proto.grpc.api.v1.MarketingCampaign.DeliveryStats;
      reader.readMessage(value,proto.grpc.api.v1.MarketingCampaign.DeliveryStats.deserializeBinaryFromReader);
      msg.setDeliveryStats(value);
      break;
    case 24:
      var value = new proto.grpc.api.v1.MarketingCampaign.EngagementStats;
      reader.readMessage(value,proto.grpc.api.v1.MarketingCampaign.EngagementStats.deserializeBinaryFromReader);
      msg.setEngagementStats(value);
      break;
    case 25:
      var value = new proto.grpc.api.v1.MarketingCampaign.ConversionStats;
      reader.readMessage(value,proto.grpc.api.v1.MarketingCampaign.ConversionStats.deserializeBinaryFromReader);
      msg.setConversionStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.MarketingCampaign.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.MarketingCampaign} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaign.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLocale();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContactFilterId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getContactFilterName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getContactFilterData();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTemplateName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTemplateDesign();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTemplateHtml();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTemplatePlaintext();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTemplatePreview();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPreviewText();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getDtCreate();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getDtUpdate();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getDtSend();
  if (f !== 0) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getDtProcessed();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getIsArchived();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getIsDeleted();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getAudienceStats();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.grpc.api.v1.MarketingCampaign.AudienceStats.serializeBinaryToWriter
    );
  }
  f = message.getPublishStats();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.grpc.api.v1.MarketingCampaign.PublishStats.serializeBinaryToWriter
    );
  }
  f = message.getDeliveryStats();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.grpc.api.v1.MarketingCampaign.DeliveryStats.serializeBinaryToWriter
    );
  }
  f = message.getEngagementStats();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.grpc.api.v1.MarketingCampaign.EngagementStats.serializeBinaryToWriter
    );
  }
  f = message.getConversionStats();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.grpc.api.v1.MarketingCampaign.ConversionStats.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.grpc.api.v1.MarketingCampaign.Status = {
  STATUS_NONE: 0,
  STATUS_DRAFT: 1,
  STATUS_SCHEDULED: 2,
  STATUS_PROCESSING: 3,
  STATUS_SENT: 4,
  STATUS_ABANDONED: 5,
  STATUS_FAILURE: 6
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.MarketingCampaign.AudienceStats.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.MarketingCampaign.AudienceStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.MarketingCampaign.AudienceStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaign.AudienceStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    all: jspb.Message.getFieldWithDefault(msg, 1, 0),
    matching: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.MarketingCampaign.AudienceStats}
 */
proto.grpc.api.v1.MarketingCampaign.AudienceStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.MarketingCampaign.AudienceStats;
  return proto.grpc.api.v1.MarketingCampaign.AudienceStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.MarketingCampaign.AudienceStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.MarketingCampaign.AudienceStats}
 */
proto.grpc.api.v1.MarketingCampaign.AudienceStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAll(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMatching(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.MarketingCampaign.AudienceStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.MarketingCampaign.AudienceStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.MarketingCampaign.AudienceStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaign.AudienceStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAll();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMatching();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 all = 1;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.AudienceStats.prototype.getAll = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.AudienceStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.AudienceStats.prototype.setAll = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 matching = 2;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.AudienceStats.prototype.getMatching = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.AudienceStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.AudienceStats.prototype.setMatching = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.MarketingCampaign.PublishStats.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.MarketingCampaign.PublishStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.MarketingCampaign.PublishStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaign.PublishStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    all: jspb.Message.getFieldWithDefault(msg, 1, 0),
    scheduled: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sent: jspb.Message.getFieldWithDefault(msg, 3, 0),
    processing: jspb.Message.getFieldWithDefault(msg, 4, 0),
    abandoned: jspb.Message.getFieldWithDefault(msg, 5, 0),
    failure: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.MarketingCampaign.PublishStats}
 */
proto.grpc.api.v1.MarketingCampaign.PublishStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.MarketingCampaign.PublishStats;
  return proto.grpc.api.v1.MarketingCampaign.PublishStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.MarketingCampaign.PublishStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.MarketingCampaign.PublishStats}
 */
proto.grpc.api.v1.MarketingCampaign.PublishStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAll(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScheduled(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProcessing(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAbandoned(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFailure(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.MarketingCampaign.PublishStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.MarketingCampaign.PublishStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.MarketingCampaign.PublishStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaign.PublishStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAll();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getScheduled();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSent();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getProcessing();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAbandoned();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getFailure();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional int64 all = 1;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.PublishStats.prototype.getAll = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.PublishStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.PublishStats.prototype.setAll = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 scheduled = 2;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.PublishStats.prototype.getScheduled = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.PublishStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.PublishStats.prototype.setScheduled = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 sent = 3;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.PublishStats.prototype.getSent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.PublishStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.PublishStats.prototype.setSent = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 processing = 4;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.PublishStats.prototype.getProcessing = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.PublishStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.PublishStats.prototype.setProcessing = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 abandoned = 5;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.PublishStats.prototype.getAbandoned = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.PublishStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.PublishStats.prototype.setAbandoned = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 failure = 6;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.PublishStats.prototype.getFailure = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.PublishStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.PublishStats.prototype.setFailure = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.MarketingCampaign.DeliveryStats.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.MarketingCampaign.DeliveryStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.MarketingCampaign.DeliveryStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaign.DeliveryStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    processed: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dropped: jspb.Message.getFieldWithDefault(msg, 2, 0),
    delivered: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deferred: jspb.Message.getFieldWithDefault(msg, 4, 0),
    bounce: jspb.Message.getFieldWithDefault(msg, 5, 0),
    blocked: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.MarketingCampaign.DeliveryStats}
 */
proto.grpc.api.v1.MarketingCampaign.DeliveryStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.MarketingCampaign.DeliveryStats;
  return proto.grpc.api.v1.MarketingCampaign.DeliveryStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.MarketingCampaign.DeliveryStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.MarketingCampaign.DeliveryStats}
 */
proto.grpc.api.v1.MarketingCampaign.DeliveryStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProcessed(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDropped(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDelivered(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeferred(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBounce(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBlocked(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.MarketingCampaign.DeliveryStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.MarketingCampaign.DeliveryStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.MarketingCampaign.DeliveryStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaign.DeliveryStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessed();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDropped();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDelivered();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDeferred();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getBounce();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getBlocked();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional int64 processed = 1;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.DeliveryStats.prototype.getProcessed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.DeliveryStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.DeliveryStats.prototype.setProcessed = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 dropped = 2;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.DeliveryStats.prototype.getDropped = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.DeliveryStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.DeliveryStats.prototype.setDropped = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 delivered = 3;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.DeliveryStats.prototype.getDelivered = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.DeliveryStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.DeliveryStats.prototype.setDelivered = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 deferred = 4;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.DeliveryStats.prototype.getDeferred = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.DeliveryStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.DeliveryStats.prototype.setDeferred = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 bounce = 5;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.DeliveryStats.prototype.getBounce = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.DeliveryStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.DeliveryStats.prototype.setBounce = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 blocked = 6;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.DeliveryStats.prototype.getBlocked = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.DeliveryStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.DeliveryStats.prototype.setBlocked = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.MarketingCampaign.EngagementStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.MarketingCampaign.EngagementStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    open: jspb.Message.getFieldWithDefault(msg, 1, 0),
    uniqueOpen: jspb.Message.getFieldWithDefault(msg, 2, 0),
    click: jspb.Message.getFieldWithDefault(msg, 3, 0),
    uniqueClick: jspb.Message.getFieldWithDefault(msg, 4, 0),
    spam: jspb.Message.getFieldWithDefault(msg, 5, 0),
    unsubscribe: jspb.Message.getFieldWithDefault(msg, 6, 0),
    groupUnsubscribe: jspb.Message.getFieldWithDefault(msg, 7, 0),
    groupResubscribe: jspb.Message.getFieldWithDefault(msg, 8, 0),
    widgetOpen: jspb.Message.getFieldWithDefault(msg, 9, 0),
    uniqueWidgetOpen: jspb.Message.getFieldWithDefault(msg, 10, 0),
    widgetReservation: jspb.Message.getFieldWithDefault(msg, 11, 0),
    uniqueWidgetReservation: jspb.Message.getFieldWithDefault(msg, 12, 0),
    widgetUnsubscribe: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.MarketingCampaign.EngagementStats}
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.MarketingCampaign.EngagementStats;
  return proto.grpc.api.v1.MarketingCampaign.EngagementStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.MarketingCampaign.EngagementStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.MarketingCampaign.EngagementStats}
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOpen(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUniqueOpen(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClick(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUniqueClick(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSpam(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnsubscribe(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupUnsubscribe(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGroupResubscribe(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWidgetOpen(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUniqueWidgetOpen(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWidgetReservation(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUniqueWidgetReservation(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWidgetUnsubscribe(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.MarketingCampaign.EngagementStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.MarketingCampaign.EngagementStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpen();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUniqueOpen();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getClick();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getUniqueClick();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getSpam();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getUnsubscribe();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getGroupUnsubscribe();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getGroupResubscribe();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getWidgetOpen();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getUniqueWidgetOpen();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getWidgetReservation();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getUniqueWidgetReservation();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getWidgetUnsubscribe();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
};


/**
 * optional int64 open = 1;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.getOpen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.EngagementStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.setOpen = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 unique_open = 2;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.getUniqueOpen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.EngagementStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.setUniqueOpen = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 click = 3;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.getClick = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.EngagementStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.setClick = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 unique_click = 4;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.getUniqueClick = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.EngagementStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.setUniqueClick = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 spam = 5;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.getSpam = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.EngagementStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.setSpam = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 unsubscribe = 6;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.getUnsubscribe = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.EngagementStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.setUnsubscribe = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 group_unsubscribe = 7;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.getGroupUnsubscribe = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.EngagementStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.setGroupUnsubscribe = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 group_resubscribe = 8;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.getGroupResubscribe = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.EngagementStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.setGroupResubscribe = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 widget_open = 9;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.getWidgetOpen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.EngagementStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.setWidgetOpen = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 unique_widget_open = 10;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.getUniqueWidgetOpen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.EngagementStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.setUniqueWidgetOpen = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 widget_reservation = 11;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.getWidgetReservation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.EngagementStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.setWidgetReservation = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 unique_widget_reservation = 12;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.getUniqueWidgetReservation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.EngagementStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.setUniqueWidgetReservation = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 widget_unsubscribe = 13;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.getWidgetUnsubscribe = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.EngagementStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.EngagementStats.prototype.setWidgetUnsubscribe = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.MarketingCampaign.ConversionStats.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.MarketingCampaign.ConversionStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.MarketingCampaign.ConversionStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaign.ConversionStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    all: jspb.Message.getFieldWithDefault(msg, 1, 0),
    imminent: jspb.Message.getFieldWithDefault(msg, 2, 0),
    uniqueAll: jspb.Message.getFieldWithDefault(msg, 3, 0),
    uniqueImminent: jspb.Message.getFieldWithDefault(msg, 4, 0),
    guestsAll: jspb.Message.getFieldWithDefault(msg, 5, 0),
    guestsImminent: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.MarketingCampaign.ConversionStats}
 */
proto.grpc.api.v1.MarketingCampaign.ConversionStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.MarketingCampaign.ConversionStats;
  return proto.grpc.api.v1.MarketingCampaign.ConversionStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.MarketingCampaign.ConversionStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.MarketingCampaign.ConversionStats}
 */
proto.grpc.api.v1.MarketingCampaign.ConversionStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAll(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setImminent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUniqueAll(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUniqueImminent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGuestsAll(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGuestsImminent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.MarketingCampaign.ConversionStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.MarketingCampaign.ConversionStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.MarketingCampaign.ConversionStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaign.ConversionStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAll();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getImminent();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUniqueAll();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getUniqueImminent();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getGuestsAll();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getGuestsImminent();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional int64 all = 1;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.ConversionStats.prototype.getAll = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.ConversionStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.ConversionStats.prototype.setAll = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 imminent = 2;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.ConversionStats.prototype.getImminent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.ConversionStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.ConversionStats.prototype.setImminent = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 unique_all = 3;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.ConversionStats.prototype.getUniqueAll = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.ConversionStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.ConversionStats.prototype.setUniqueAll = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 unique_imminent = 4;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.ConversionStats.prototype.getUniqueImminent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.ConversionStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.ConversionStats.prototype.setUniqueImminent = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 guests_all = 5;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.ConversionStats.prototype.getGuestsAll = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.ConversionStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.ConversionStats.prototype.setGuestsAll = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 guests_imminent = 6;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.ConversionStats.prototype.getGuestsImminent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign.ConversionStats} returns this
 */
proto.grpc.api.v1.MarketingCampaign.ConversionStats.prototype.setGuestsImminent = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string locale = 2;
 * @return {string}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getLocale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.setLocale = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string subject = 4;
 * @return {string}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 contact_filter_id = 5;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getContactFilterId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.setContactFilterId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string contact_filter_name = 6;
 * @return {string}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getContactFilterName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.setContactFilterName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string contact_filter_data = 7;
 * @return {string}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getContactFilterData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.setContactFilterData = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string template_name = 8;
 * @return {string}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getTemplateName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.setTemplateName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string template_design = 9;
 * @return {string}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getTemplateDesign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.setTemplateDesign = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string template_html = 10;
 * @return {string}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getTemplateHtml = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.setTemplateHtml = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string template_plaintext = 11;
 * @return {string}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getTemplatePlaintext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.setTemplatePlaintext = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string template_preview = 12;
 * @return {string}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getTemplatePreview = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.setTemplatePreview = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string preview_text = 13;
 * @return {string}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getPreviewText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.setPreviewText = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional Status status = 14;
 * @return {!proto.grpc.api.v1.MarketingCampaign.Status}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getStatus = function() {
  return /** @type {!proto.grpc.api.v1.MarketingCampaign.Status} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.grpc.api.v1.MarketingCampaign.Status} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional int64 dt_create = 15;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getDtCreate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.setDtCreate = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int64 dt_update = 16;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getDtUpdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.setDtUpdate = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int64 dt_send = 17;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getDtSend = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.setDtSend = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int64 dt_processed = 18;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getDtProcessed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.setDtProcessed = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional bool is_archived = 19;
 * @return {boolean}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getIsArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.setIsArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool is_deleted = 20;
 * @return {boolean}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.setIsDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional AudienceStats audience_stats = 21;
 * @return {?proto.grpc.api.v1.MarketingCampaign.AudienceStats}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getAudienceStats = function() {
  return /** @type{?proto.grpc.api.v1.MarketingCampaign.AudienceStats} */ (
    jspb.Message.getWrapperField(this, proto.grpc.api.v1.MarketingCampaign.AudienceStats, 21));
};


/**
 * @param {?proto.grpc.api.v1.MarketingCampaign.AudienceStats|undefined} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
*/
proto.grpc.api.v1.MarketingCampaign.prototype.setAudienceStats = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.clearAudienceStats = function() {
  return this.setAudienceStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.hasAudienceStats = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional PublishStats publish_stats = 22;
 * @return {?proto.grpc.api.v1.MarketingCampaign.PublishStats}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getPublishStats = function() {
  return /** @type{?proto.grpc.api.v1.MarketingCampaign.PublishStats} */ (
    jspb.Message.getWrapperField(this, proto.grpc.api.v1.MarketingCampaign.PublishStats, 22));
};


/**
 * @param {?proto.grpc.api.v1.MarketingCampaign.PublishStats|undefined} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
*/
proto.grpc.api.v1.MarketingCampaign.prototype.setPublishStats = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.clearPublishStats = function() {
  return this.setPublishStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.hasPublishStats = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional DeliveryStats delivery_stats = 23;
 * @return {?proto.grpc.api.v1.MarketingCampaign.DeliveryStats}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getDeliveryStats = function() {
  return /** @type{?proto.grpc.api.v1.MarketingCampaign.DeliveryStats} */ (
    jspb.Message.getWrapperField(this, proto.grpc.api.v1.MarketingCampaign.DeliveryStats, 23));
};


/**
 * @param {?proto.grpc.api.v1.MarketingCampaign.DeliveryStats|undefined} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
*/
proto.grpc.api.v1.MarketingCampaign.prototype.setDeliveryStats = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.clearDeliveryStats = function() {
  return this.setDeliveryStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.hasDeliveryStats = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional EngagementStats engagement_stats = 24;
 * @return {?proto.grpc.api.v1.MarketingCampaign.EngagementStats}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getEngagementStats = function() {
  return /** @type{?proto.grpc.api.v1.MarketingCampaign.EngagementStats} */ (
    jspb.Message.getWrapperField(this, proto.grpc.api.v1.MarketingCampaign.EngagementStats, 24));
};


/**
 * @param {?proto.grpc.api.v1.MarketingCampaign.EngagementStats|undefined} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
*/
proto.grpc.api.v1.MarketingCampaign.prototype.setEngagementStats = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.clearEngagementStats = function() {
  return this.setEngagementStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.hasEngagementStats = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional ConversionStats conversion_stats = 25;
 * @return {?proto.grpc.api.v1.MarketingCampaign.ConversionStats}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.getConversionStats = function() {
  return /** @type{?proto.grpc.api.v1.MarketingCampaign.ConversionStats} */ (
    jspb.Message.getWrapperField(this, proto.grpc.api.v1.MarketingCampaign.ConversionStats, 25));
};


/**
 * @param {?proto.grpc.api.v1.MarketingCampaign.ConversionStats|undefined} value
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
*/
proto.grpc.api.v1.MarketingCampaign.prototype.setConversionStats = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.MarketingCampaign} returns this
 */
proto.grpc.api.v1.MarketingCampaign.prototype.clearConversionStats = function() {
  return this.setConversionStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.MarketingCampaign.prototype.hasConversionStats = function() {
  return jspb.Message.getField(this, 25) != null;
};


goog.object.extend(exports, proto.grpc.api.v1);
