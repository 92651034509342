import * as pbs from '@/grpc-pb/html_template_service_pb';
import { strcmp } from '@/services/common';
import * as pbm from '@/grpc-pb/html_template_pb';

export default class HtmlTemplate {
  id: number = 0;

  name: string = '';

  design?: string;

  html?: string;

  preview?: string;

  plaintext?: string;

  created: Date = new Date();

  updated: Date = new Date();

  isDeleted?: boolean;

  isDefault: boolean = false;

  get value() { return this.id; }

  get text() { return this.name; }

  static fromGrpcResponse(r: pbs.HtmlTemplateListResponse): HtmlTemplate {
    const e = r.getHtmltemplate();
    return HtmlTemplate.fromGrpcModel(e);
  }

  static compare(o1: HtmlTemplate, o2: HtmlTemplate): number {
    let res = (o1.isDefault ? 1 : -1) - (o2.isDefault ? 1 : -1);
    if (res !== 0) return -res;

    res = strcmp(o1.name ?? '', o2.name ?? '');
    if (res !== 0) return res;

    return o1.id - o2.id;
  }

  constructor(o?: { id?: number, name: string, design?: string, html?: string, plaintext?: string, preview?: string }) {
    this.id = o?.id ?? 0;
    this.name = o?.name ?? '';
    this.design = o?.design;
    this.html = o?.html;
    this.preview = o?.preview;
    this.plaintext = o?.plaintext;
  }

  static fromGrpcModel(o?: pbm.HtmlTemplate): HtmlTemplate {
    const e = new HtmlTemplate();

    e.id = o?.getId() ?? 0;
    e.name = o?.getName() || 'N/A';
    e.design = o?.getDesign();
    // e.html = o?.getHtml(); - no html from grpc
    // e.plaintext = o?.getPlaintext(); - no plaintext from grpc
    e.preview = o?.getPreview();
    e.created = o?.getDtCreate() ? new Date(o.getDtCreate() * 1000) : new Date();
    e.updated = o?.getDtUpdate() ? new Date(o.getDtUpdate() * 1000) : new Date();
    e.isDeleted = o?.getIsDeleted();
    e.isDefault = o?.getIsDefault() ?? false;

    return e;
  }

  toGrpcModel(): pbm.HtmlTemplate {
    const o = new pbm.HtmlTemplate();

    o.setId(this.id);
    o.setName(this.name);
    if (this.design) o.setDesign(this.design);
    if (this.html) o.setHtml(this.html);
    if (this.plaintext) o.setPlaintext(this.plaintext);
    // if (this.preview) o.setPreview(this.preview); - no preview to grpc
    if (this.isDeleted) o.setIsDeleted(this.isDeleted);

    return o;
  }
}

export function areHtmlTemplatesSame(o1: HtmlTemplate, o2: HtmlTemplate): boolean {
  return o1.design === o2.design && o1.name === o2.name;
}
