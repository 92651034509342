// source: marketing_campaign_manager_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var empty_pb = require('./empty_pb.js');
goog.object.extend(proto, empty_pb);
var marketing_campaign_pb = require('./marketing_campaign_pb.js');
goog.object.extend(proto, marketing_campaign_pb);
var marketing_campaign_settings_pb = require('./marketing_campaign_settings_pb.js');
goog.object.extend(proto, marketing_campaign_settings_pb);
goog.exportSymbol('proto.grpc.api.v1.ListCampaignsRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.ListCampaignsRequestParams', null, global);
goog.exportSymbol('proto.grpc.api.v1.MarketingCampaignArchiveRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.MarketingCampaignIdRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse', null, global);
goog.exportSymbol('proto.grpc.api.v1.MarketingCampaignRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.MarketingCampaignResponse', null, global);
goog.exportSymbol('proto.grpc.api.v1.MarketingCampaignSendRequest', null, global);
goog.exportSymbol('proto.grpc.api.v1.MarketingCampaignSettingsResponse', null, global);
goog.exportSymbol('proto.grpc.api.v1.MarketingCampaignTestRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.MarketingCampaignIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.MarketingCampaignIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.MarketingCampaignIdRequest.displayName = 'proto.grpc.api.v1.MarketingCampaignIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.MarketingCampaignRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.MarketingCampaignRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.MarketingCampaignRequest.displayName = 'proto.grpc.api.v1.MarketingCampaignRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.MarketingCampaignTestRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.api.v1.MarketingCampaignTestRequest.repeatedFields_, null);
};
goog.inherits(proto.grpc.api.v1.MarketingCampaignTestRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.MarketingCampaignTestRequest.displayName = 'proto.grpc.api.v1.MarketingCampaignTestRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.MarketingCampaignArchiveRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.MarketingCampaignArchiveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.MarketingCampaignArchiveRequest.displayName = 'proto.grpc.api.v1.MarketingCampaignArchiveRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.MarketingCampaignSendRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.MarketingCampaignSendRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.MarketingCampaignSendRequest.displayName = 'proto.grpc.api.v1.MarketingCampaignSendRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.MarketingCampaignResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.MarketingCampaignResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.MarketingCampaignResponse.displayName = 'proto.grpc.api.v1.MarketingCampaignResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.ListCampaignsRequestParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.ListCampaignsRequestParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.ListCampaignsRequestParams.displayName = 'proto.grpc.api.v1.ListCampaignsRequestParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.ListCampaignsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.ListCampaignsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.ListCampaignsRequest.displayName = 'proto.grpc.api.v1.ListCampaignsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest.displayName = 'proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse.displayName = 'proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.api.v1.MarketingCampaignSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.api.v1.MarketingCampaignSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.api.v1.MarketingCampaignSettingsResponse.displayName = 'proto.grpc.api.v1.MarketingCampaignSettingsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.MarketingCampaignIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.MarketingCampaignIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.MarketingCampaignIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaignIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.MarketingCampaignIdRequest}
 */
proto.grpc.api.v1.MarketingCampaignIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.MarketingCampaignIdRequest;
  return proto.grpc.api.v1.MarketingCampaignIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.MarketingCampaignIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.MarketingCampaignIdRequest}
 */
proto.grpc.api.v1.MarketingCampaignIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.MarketingCampaignIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.MarketingCampaignIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.MarketingCampaignIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaignIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaignIdRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaignIdRequest} returns this
 */
proto.grpc.api.v1.MarketingCampaignIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.MarketingCampaignRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.MarketingCampaignRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.MarketingCampaignRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaignRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketingCampaign: (f = msg.getMarketingCampaign()) && marketing_campaign_pb.MarketingCampaign.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.MarketingCampaignRequest}
 */
proto.grpc.api.v1.MarketingCampaignRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.MarketingCampaignRequest;
  return proto.grpc.api.v1.MarketingCampaignRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.MarketingCampaignRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.MarketingCampaignRequest}
 */
proto.grpc.api.v1.MarketingCampaignRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new marketing_campaign_pb.MarketingCampaign;
      reader.readMessage(value,marketing_campaign_pb.MarketingCampaign.deserializeBinaryFromReader);
      msg.setMarketingCampaign(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.MarketingCampaignRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.MarketingCampaignRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.MarketingCampaignRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaignRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketingCampaign();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      marketing_campaign_pb.MarketingCampaign.serializeBinaryToWriter
    );
  }
};


/**
 * optional MarketingCampaign marketing_campaign = 1;
 * @return {?proto.grpc.api.v1.MarketingCampaign}
 */
proto.grpc.api.v1.MarketingCampaignRequest.prototype.getMarketingCampaign = function() {
  return /** @type{?proto.grpc.api.v1.MarketingCampaign} */ (
    jspb.Message.getWrapperField(this, marketing_campaign_pb.MarketingCampaign, 1));
};


/**
 * @param {?proto.grpc.api.v1.MarketingCampaign|undefined} value
 * @return {!proto.grpc.api.v1.MarketingCampaignRequest} returns this
*/
proto.grpc.api.v1.MarketingCampaignRequest.prototype.setMarketingCampaign = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.MarketingCampaignRequest} returns this
 */
proto.grpc.api.v1.MarketingCampaignRequest.prototype.clearMarketingCampaign = function() {
  return this.setMarketingCampaign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.MarketingCampaignRequest.prototype.hasMarketingCampaign = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.api.v1.MarketingCampaignTestRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.MarketingCampaignTestRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.MarketingCampaignTestRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.MarketingCampaignTestRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaignTestRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    recipientsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.MarketingCampaignTestRequest}
 */
proto.grpc.api.v1.MarketingCampaignTestRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.MarketingCampaignTestRequest;
  return proto.grpc.api.v1.MarketingCampaignTestRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.MarketingCampaignTestRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.MarketingCampaignTestRequest}
 */
proto.grpc.api.v1.MarketingCampaignTestRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addRecipients(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.MarketingCampaignTestRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.MarketingCampaignTestRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.MarketingCampaignTestRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaignTestRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getRecipientsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaignTestRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaignTestRequest} returns this
 */
proto.grpc.api.v1.MarketingCampaignTestRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated string recipients = 2;
 * @return {!Array<string>}
 */
proto.grpc.api.v1.MarketingCampaignTestRequest.prototype.getRecipientsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpc.api.v1.MarketingCampaignTestRequest} returns this
 */
proto.grpc.api.v1.MarketingCampaignTestRequest.prototype.setRecipientsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpc.api.v1.MarketingCampaignTestRequest} returns this
 */
proto.grpc.api.v1.MarketingCampaignTestRequest.prototype.addRecipients = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.api.v1.MarketingCampaignTestRequest} returns this
 */
proto.grpc.api.v1.MarketingCampaignTestRequest.prototype.clearRecipientsList = function() {
  return this.setRecipientsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.MarketingCampaignArchiveRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.MarketingCampaignArchiveRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.MarketingCampaignArchiveRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaignArchiveRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isAchived: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.MarketingCampaignArchiveRequest}
 */
proto.grpc.api.v1.MarketingCampaignArchiveRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.MarketingCampaignArchiveRequest;
  return proto.grpc.api.v1.MarketingCampaignArchiveRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.MarketingCampaignArchiveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.MarketingCampaignArchiveRequest}
 */
proto.grpc.api.v1.MarketingCampaignArchiveRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAchived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.MarketingCampaignArchiveRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.MarketingCampaignArchiveRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.MarketingCampaignArchiveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaignArchiveRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getIsAchived();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaignArchiveRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaignArchiveRequest} returns this
 */
proto.grpc.api.v1.MarketingCampaignArchiveRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool is_achived = 2;
 * @return {boolean}
 */
proto.grpc.api.v1.MarketingCampaignArchiveRequest.prototype.getIsAchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.api.v1.MarketingCampaignArchiveRequest} returns this
 */
proto.grpc.api.v1.MarketingCampaignArchiveRequest.prototype.setIsAchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.MarketingCampaignSendRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.MarketingCampaignSendRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.MarketingCampaignSendRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaignSendRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    omitCampaignCheck: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.MarketingCampaignSendRequest}
 */
proto.grpc.api.v1.MarketingCampaignSendRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.MarketingCampaignSendRequest;
  return proto.grpc.api.v1.MarketingCampaignSendRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.MarketingCampaignSendRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.MarketingCampaignSendRequest}
 */
proto.grpc.api.v1.MarketingCampaignSendRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOmitCampaignCheck(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.MarketingCampaignSendRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.MarketingCampaignSendRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.MarketingCampaignSendRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaignSendRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOmitCampaignCheck();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.grpc.api.v1.MarketingCampaignSendRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.MarketingCampaignSendRequest} returns this
 */
proto.grpc.api.v1.MarketingCampaignSendRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool omit_campaign_check = 2;
 * @return {boolean}
 */
proto.grpc.api.v1.MarketingCampaignSendRequest.prototype.getOmitCampaignCheck = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.api.v1.MarketingCampaignSendRequest} returns this
 */
proto.grpc.api.v1.MarketingCampaignSendRequest.prototype.setOmitCampaignCheck = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.MarketingCampaignResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.MarketingCampaignResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.MarketingCampaignResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaignResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketingCampaign: (f = msg.getMarketingCampaign()) && marketing_campaign_pb.MarketingCampaign.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.MarketingCampaignResponse}
 */
proto.grpc.api.v1.MarketingCampaignResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.MarketingCampaignResponse;
  return proto.grpc.api.v1.MarketingCampaignResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.MarketingCampaignResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.MarketingCampaignResponse}
 */
proto.grpc.api.v1.MarketingCampaignResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new marketing_campaign_pb.MarketingCampaign;
      reader.readMessage(value,marketing_campaign_pb.MarketingCampaign.deserializeBinaryFromReader);
      msg.setMarketingCampaign(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.MarketingCampaignResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.MarketingCampaignResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.MarketingCampaignResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaignResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketingCampaign();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      marketing_campaign_pb.MarketingCampaign.serializeBinaryToWriter
    );
  }
};


/**
 * optional MarketingCampaign marketing_campaign = 1;
 * @return {?proto.grpc.api.v1.MarketingCampaign}
 */
proto.grpc.api.v1.MarketingCampaignResponse.prototype.getMarketingCampaign = function() {
  return /** @type{?proto.grpc.api.v1.MarketingCampaign} */ (
    jspb.Message.getWrapperField(this, marketing_campaign_pb.MarketingCampaign, 1));
};


/**
 * @param {?proto.grpc.api.v1.MarketingCampaign|undefined} value
 * @return {!proto.grpc.api.v1.MarketingCampaignResponse} returns this
*/
proto.grpc.api.v1.MarketingCampaignResponse.prototype.setMarketingCampaign = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.MarketingCampaignResponse} returns this
 */
proto.grpc.api.v1.MarketingCampaignResponse.prototype.clearMarketingCampaign = function() {
  return this.setMarketingCampaign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.MarketingCampaignResponse.prototype.hasMarketingCampaign = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.ListCampaignsRequestParams.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.ListCampaignsRequestParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.ListCampaignsRequestParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ListCampaignsRequestParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    beginDate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    showArchived: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.ListCampaignsRequestParams}
 */
proto.grpc.api.v1.ListCampaignsRequestParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.ListCampaignsRequestParams;
  return proto.grpc.api.v1.ListCampaignsRequestParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.ListCampaignsRequestParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.ListCampaignsRequestParams}
 */
proto.grpc.api.v1.ListCampaignsRequestParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBeginDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEndDate(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowArchived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.ListCampaignsRequestParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.ListCampaignsRequestParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.ListCampaignsRequestParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ListCampaignsRequestParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBeginDate();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEndDate();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getShowArchived();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 begin_date = 1;
 * @return {number}
 */
proto.grpc.api.v1.ListCampaignsRequestParams.prototype.getBeginDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ListCampaignsRequestParams} returns this
 */
proto.grpc.api.v1.ListCampaignsRequestParams.prototype.setBeginDate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 end_date = 2;
 * @return {number}
 */
proto.grpc.api.v1.ListCampaignsRequestParams.prototype.getEndDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.api.v1.ListCampaignsRequestParams} returns this
 */
proto.grpc.api.v1.ListCampaignsRequestParams.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool show_archived = 3;
 * @return {boolean}
 */
proto.grpc.api.v1.ListCampaignsRequestParams.prototype.getShowArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.api.v1.ListCampaignsRequestParams} returns this
 */
proto.grpc.api.v1.ListCampaignsRequestParams.prototype.setShowArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.ListCampaignsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.ListCampaignsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.ListCampaignsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ListCampaignsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    params: (f = msg.getParams()) && proto.grpc.api.v1.ListCampaignsRequestParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.ListCampaignsRequest}
 */
proto.grpc.api.v1.ListCampaignsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.ListCampaignsRequest;
  return proto.grpc.api.v1.ListCampaignsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.ListCampaignsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.ListCampaignsRequest}
 */
proto.grpc.api.v1.ListCampaignsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.api.v1.ListCampaignsRequestParams;
      reader.readMessage(value,proto.grpc.api.v1.ListCampaignsRequestParams.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.ListCampaignsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.ListCampaignsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.ListCampaignsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.ListCampaignsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grpc.api.v1.ListCampaignsRequestParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional ListCampaignsRequestParams params = 1;
 * @return {?proto.grpc.api.v1.ListCampaignsRequestParams}
 */
proto.grpc.api.v1.ListCampaignsRequest.prototype.getParams = function() {
  return /** @type{?proto.grpc.api.v1.ListCampaignsRequestParams} */ (
    jspb.Message.getWrapperField(this, proto.grpc.api.v1.ListCampaignsRequestParams, 1));
};


/**
 * @param {?proto.grpc.api.v1.ListCampaignsRequestParams|undefined} value
 * @return {!proto.grpc.api.v1.ListCampaignsRequest} returns this
*/
proto.grpc.api.v1.ListCampaignsRequest.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.ListCampaignsRequest} returns this
 */
proto.grpc.api.v1.ListCampaignsRequest.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.ListCampaignsRequest.prototype.hasParams = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    contactFilterData: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest}
 */
proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest;
  return proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest}
 */
proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactFilterData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContactFilterData();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string contact_filter_data = 1;
 * @return {string}
 */
proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest.prototype.getContactFilterData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest} returns this
 */
proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest.prototype.setContactFilterData = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    audienceStats: (f = msg.getAudienceStats()) && marketing_campaign_pb.MarketingCampaign.AudienceStats.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse}
 */
proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse;
  return proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse}
 */
proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new marketing_campaign_pb.MarketingCampaign.AudienceStats;
      reader.readMessage(value,marketing_campaign_pb.MarketingCampaign.AudienceStats.deserializeBinaryFromReader);
      msg.setAudienceStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAudienceStats();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      marketing_campaign_pb.MarketingCampaign.AudienceStats.serializeBinaryToWriter
    );
  }
};


/**
 * optional MarketingCampaign.AudienceStats audience_stats = 1;
 * @return {?proto.grpc.api.v1.MarketingCampaign.AudienceStats}
 */
proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse.prototype.getAudienceStats = function() {
  return /** @type{?proto.grpc.api.v1.MarketingCampaign.AudienceStats} */ (
    jspb.Message.getWrapperField(this, marketing_campaign_pb.MarketingCampaign.AudienceStats, 1));
};


/**
 * @param {?proto.grpc.api.v1.MarketingCampaign.AudienceStats|undefined} value
 * @return {!proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse} returns this
*/
proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse.prototype.setAudienceStats = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse} returns this
 */
proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse.prototype.clearAudienceStats = function() {
  return this.setAudienceStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse.prototype.hasAudienceStats = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.api.v1.MarketingCampaignSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.api.v1.MarketingCampaignSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.api.v1.MarketingCampaignSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaignSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    settings: (f = msg.getSettings()) && marketing_campaign_settings_pb.MarketingCampaignSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.api.v1.MarketingCampaignSettingsResponse}
 */
proto.grpc.api.v1.MarketingCampaignSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.api.v1.MarketingCampaignSettingsResponse;
  return proto.grpc.api.v1.MarketingCampaignSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.api.v1.MarketingCampaignSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.api.v1.MarketingCampaignSettingsResponse}
 */
proto.grpc.api.v1.MarketingCampaignSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new marketing_campaign_settings_pb.MarketingCampaignSettings;
      reader.readMessage(value,marketing_campaign_settings_pb.MarketingCampaignSettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.api.v1.MarketingCampaignSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.api.v1.MarketingCampaignSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.api.v1.MarketingCampaignSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.api.v1.MarketingCampaignSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      marketing_campaign_settings_pb.MarketingCampaignSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional MarketingCampaignSettings settings = 1;
 * @return {?proto.grpc.api.v1.MarketingCampaignSettings}
 */
proto.grpc.api.v1.MarketingCampaignSettingsResponse.prototype.getSettings = function() {
  return /** @type{?proto.grpc.api.v1.MarketingCampaignSettings} */ (
    jspb.Message.getWrapperField(this, marketing_campaign_settings_pb.MarketingCampaignSettings, 1));
};


/**
 * @param {?proto.grpc.api.v1.MarketingCampaignSettings|undefined} value
 * @return {!proto.grpc.api.v1.MarketingCampaignSettingsResponse} returns this
*/
proto.grpc.api.v1.MarketingCampaignSettingsResponse.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.api.v1.MarketingCampaignSettingsResponse} returns this
 */
proto.grpc.api.v1.MarketingCampaignSettingsResponse.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.api.v1.MarketingCampaignSettingsResponse.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.grpc.api.v1);
