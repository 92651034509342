
import {} from '@/services/filter/filter';
import {
  FilterDescriptor, FilterRuleDescriptor, FilterSpecialCategoryOperandType, ListInfo,
} from '@/services/filter/filter-descriptor';
import {
  FilterFieldRule, FilterFieldType, FilterRule, FilterRuleType,
} from '@/services/filter/filter-rule';
import { FilterOperandType } from '@/services/filter/filter-rule-operation';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'ContactsFilterValueRow',
  components: { },
  mixins: [],
  props: {
    value: { type: Object as PropType<FilterFieldRule>, required: true },
    descriptor: { type: Object as PropType<FilterDescriptor>, required: true },
    lastRow: { type: Boolean, required: true, default: false },
    errorMessages: { type: Array as PropType<Array<String>>, required: false, default: () => [] },
    disabled: { type: Boolean as PropType<boolean>, required: false, default: false },
  },
  data() {
    return {
      ruleField: null as string | null,
      ruleType: null as FilterRuleType | null,
      ruleOperand: null as FilterOperandType | null,
      ruleDescriptor: null as FilterRuleDescriptor | null,
    };
  },
  computed: {
    ruleFieldsInfo(): ListInfo<string> {
      return this.descriptor.allowedFieldsInfo();
    },
    ruleTypesInfo(): ListInfo<FilterRuleType> {
      return this.ruleDescriptor?.allowedRuleTypesInfo() ?? [];
    },
    isOperand(): boolean {
      return !FilterRule.isFilterFieldNoOperandType(this.value.type);
    },
    ruleOperandsInfo(): ListInfo<FilterOperandType> {
      const type = this.ruleDescriptor?.specialCategoryOperandsType() ?? 'none';
      switch (type) {
        case FilterSpecialCategoryOperandType.Country: return this.$tstore.getters.sortedCountries;
        case FilterSpecialCategoryOperandType.Language: return this.$tstore.getters.sortedLocales;
        default:
      }

      return this.ruleDescriptor?.allowedOperandsInfo() ?? [];
    },
    ruleOperandType(): string {
      if (this.ruleDescriptor?.fieldtype === FilterFieldType.Number) return 'number';
      return 'string';
    },
    rule(): FilterFieldRule {
      const rule = new FilterFieldRule();
      rule.id = this.value.id;
      rule.field = this.ruleDescriptor?.field ?? '';
      rule.fieldType = this.ruleDescriptor?.fieldtype ?? FilterFieldType.None;
      rule.type = this.ruleType ?? FilterRuleType.None;
      rule.operand = this.ruleOperand ?? null;
      return rule;
    },
  },
  watch: {
    value(value: FilterRule) {
      this.reset();
    },
  },
  mounted() {
    this.reset();
    this.ruleFieldChanged();
  },
  methods: {
    reset() {
      this.ruleField = this.value.field;
      this.ruleType = this.value.type;
      this.ruleOperand = this.value.operand;
    },
    add() {
      this.$emit('add');
    },
    remove() {
      this.$emit('remove');
    },
    ruleFieldChanged() {
      this.ruleDescriptor = this.descriptor.ruleDescriptors.find((rd) => rd.field === this.ruleField) ?? null;
      if (!this.ruleDescriptor?.allowedRuleTypes.includes(this.value.type)) {
        this.ruleType = this.ruleDescriptor?.allowedRuleTypes.find(Boolean) ?? null;
      }
      if (this.ruleOperand && this.ruleDescriptor?.validateOperand(this.rule) !== null) {
        this.ruleOperand = null;
      }
      this.$emit('input', this.rule);
    },
    ruleTypeChanged() {
      if (this.ruleOperand && this.ruleDescriptor?.validateOperand(this.rule) !== null) {
        this.ruleOperand = null;
      }
      this.$emit('input', this.rule);
    },
    ruleOperandChanged() {
      this.$emit('input', this.rule);
    },
  },
});
