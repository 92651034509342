/* eslint-disable max-len */
/* eslint-disable vue/max-len */
import { GetterTree, MutationTree, ActionTree } from 'vuex';
import grpcClient from '@/grpc-api/grpc-client';
import { deleteModelEntities, mergeSortedModelEntities } from '@/grpc-api/model/model-utils';
import { searchContactReset } from '@/services/contact-search';
import MarketingCampaign, {
  MarketingCampaignFilter, MarketingCampaignMapping, areMarketingCampaignFiltersSame, defaultMarketingCampaignFilter,
} from '@/grpc-api/model/marketing-campaign';
import ContactFilter from '@/grpc-api/model/contact-filter';
import { dateIndexFromDate } from '@/services/time-utils';
import { DateRange } from '@/services/date-range';
import HtmlTemplate from '@/grpc-api/model/html-template';
import MarketingCampaignSettings from '@/grpc-api/model/marketing-campaign-settings';
import RepeatableTimer from '@/services/repeatable-timer';
import IRootState, { IMarketingCampaignManagerState } from './store-state';

export class MarketingCampaignManagerState implements IMarketingCampaignManagerState {
  settings: MarketingCampaignSettings = new MarketingCampaignSettings();

  campaignFilter: MarketingCampaignFilter = defaultMarketingCampaignFilter();

  campaigns: MarketingCampaign[] = [];

  contactFilters: ContactFilter[] = [];

  htmlTemplates: HtmlTemplate[] = [];

  isLoaded: boolean = false;

  areCampaignsLoaded: boolean = false;
}

const mutations = <MutationTree<IMarketingCampaignManagerState>>{
  RESET(state: MarketingCampaignManagerState) {
    Object.assign(state, new MarketingCampaignManagerState());
    searchContactReset();
  },
  RESET_MARKETING_CAMPAIGN_MANAGER(state: MarketingCampaignManagerState) {
    Object.assign(state, new MarketingCampaignManagerState());
    searchContactReset();
  },
  RESET_MARKETING_CAMPAIGN_MANAGER_CAMPAIGNS(state: MarketingCampaignManagerState) {
    state.campaigns = [];
    state.areCampaignsLoaded = false;
  },
  UPDATE_MARKETING_CAMPAIGN_MANAGER(state: MarketingCampaignManagerState, p: {
    campaigns?: MarketingCampaign[], contactFilters?: ContactFilter[], htmlTemplates?: HtmlTemplate[], isLoaded?: boolean, areCampaignsLoaded?: boolean,
    dateRange?: DateRange, showArchived?: boolean,
    settings?: MarketingCampaignSettings,
  }) {
    if (p.settings) {
      state.settings = p.settings;
    }
    if (p.campaigns) {
      // handle campaign archive
      // eslint-disable-next-line no-param-reassign
      p.campaigns.forEach((c) => { if (!state.campaignFilter.showArchived && c.isArchived) c.isDeleted = true; });

      deleteModelEntities(state.campaigns, p.campaigns);
      mergeSortedModelEntities(state.campaigns, p.campaigns.sort(MarketingCampaign.compare), MarketingCampaign.compare);
    }
    if (p.htmlTemplates) {
      deleteModelEntities(state.htmlTemplates, p.htmlTemplates);
      mergeSortedModelEntities(state.htmlTemplates, p.htmlTemplates.sort(HtmlTemplate.compare), HtmlTemplate.compare);
    }

    if (p.contactFilters) {
      const contactFilters = p.contactFilters.filter((f) => f.order >= 0 || f.order < -10);
      deleteModelEntities(state.contactFilters, contactFilters);
      mergeSortedModelEntities(state.contactFilters, contactFilters.sort(ContactFilter.compare), ContactFilter.compare);
    }

    if (p.isLoaded !== undefined) state.isLoaded = p.isLoaded;
    if (p.areCampaignsLoaded !== undefined) state.areCampaignsLoaded = p.areCampaignsLoaded;

    if (p.dateRange !== undefined) state.campaignFilter.dateRange = p.dateRange;
    if (p.showArchived !== undefined) state.campaignFilter.showArchived = p.showArchived;

    console.log('settings=', p.settings);
    console.log('campaigns=', p.campaigns);
    console.log('contactFilters=', p.contactFilters);
    console.log('htmlTemplates=', p.htmlTemplates);
  },
};

const actions = <ActionTree<IMarketingCampaignManagerState, IRootState>>{
  async resetMarketingCampaignManager({ commit }) {
    commit('RESET_MARKETING_CAMPAIGN_MANAGER');
  },
  async loadMarketingCampaignManager({ state, commit, rootGetters }) {
    if (!rootGetters.isLoaded) return;

    commit('RESET_MARKETING_CAMPAIGN_MANAGER');

    const { dateRange, showArchived } = state.campaignFilter;
    const beginDateIndex = dateIndexFromDate(dateRange.beginDate);
    const endDateIndex = dateIndexFromDate(dateRange.endDate);

    let allCampaignsLoaded = false;

    await Promise.all([
      // settings
      (async () => {
        const settings = await grpcClient.getMarketingCampaignSettings();
        commit('UPDATE_MARKETING_CAMPAIGN_MANAGER', { settings });
      })(),
      // campaigns
      grpcClient.listMarketingCampaigns({ beginDateIndex, endDateIndex, showArchived }, (data: MarketingCampaign[]) => {
        if (data.length === 0) allCampaignsLoaded = true;
        commit('UPDATE_MARKETING_CAMPAIGN_MANAGER', { campaigns: data, isFullyLoaded: state.isLoaded && allCampaignsLoaded });
      }, rootGetters),
      // contact filters
      grpcClient.listAllContactFilters((data: ContactFilter[]) => { commit('UPDATE_MARKETING_CAMPAIGN_MANAGER', { contactFilters: data }); }),
      // html templates
      grpcClient.listAllHtmlTemplates((data: HtmlTemplate[]) => {
        commit('UPDATE_MARKETING_CAMPAIGN_MANAGER', { htmlTemplates: data });
      }),
    ]);

    commit('UPDATE_MARKETING_CAMPAIGN_MANAGER', { isLoaded: true, isFullyLoaded: allCampaignsLoaded });
  },
  async loadMarketingCampaigns({ state, commit, rootGetters }, p?: { noReset: boolean }) {
    if (!rootGetters.isLoaded || !state.isLoaded) return;

    if (!p?.noReset) commit('RESET_MARKETING_CAMPAIGN_MANAGER_CAMPAIGNS');

    const thisFilter = { ...state.campaignFilter };

    const { dateRange, showArchived } = state.campaignFilter;
    const beginDateIndex = dateIndexFromDate(dateRange.beginDate);
    const endDateIndex = dateIndexFromDate(dateRange.endDate);

    await Promise.all([
      // settings
      (async () => {
        const settings = await grpcClient.getMarketingCampaignSettings();
        commit('UPDATE_MARKETING_CAMPAIGN_MANAGER', { settings });
      })(),
      // campaigns
      grpcClient.listMarketingCampaigns({ beginDateIndex, endDateIndex, showArchived }, (data: MarketingCampaign[]) => {
        if (!areMarketingCampaignFiltersSame(thisFilter, state.campaignFilter)) return;
        commit('UPDATE_MARKETING_CAMPAIGN_MANAGER', { campaigns: data, isFullyLoaded: data.length === 0 });
      }, rootGetters),
    ]);
  },
  async refreshMarketingCampaigns({ dispatch }) {
    dispatch('loadMarketingCampaigns', { noReset: true });
  },
  async updateMarketingCampaignFilter({ state, commit, dispatch }, p: { dateRange?: DateRange, showArchived?: boolean }) {
    commit('UPDATE_MARKETING_CAMPAIGN_MANAGER', { dateRange: p.dateRange, showArchived: p.showArchived });
    await dispatch('loadMarketingCampaigns');
  },
  async updateMarketingCampaign({ commit, rootGetters }, p: { campaign: MarketingCampaign }) {
    const campaign = await grpcClient.updateMarketingCampaign(p.campaign, rootGetters as MarketingCampaignMapping);
    commit('UPDATE_MARKETING_CAMPAIGN_MANAGER', { campaigns: [campaign] });
    return campaign;
  },
  async getMarketingCampaign({ commit, rootGetters }, p: { campaign: MarketingCampaign }) {
    const campaign = await grpcClient.getMarketingCampaign(p.campaign, rootGetters as MarketingCampaignMapping);
    commit('UPDATE_MARKETING_CAMPAIGN_MANAGER', { campaigns: [campaign] });
    return campaign;
  },
  async sendMarketingCampaign({ commit, rootGetters }, p: { campaign: MarketingCampaign, omitCampaignCheck: boolean }) {
    const campaign = await grpcClient.sendMarketingCampaign(p.campaign, p.omitCampaignCheck, rootGetters as MarketingCampaignMapping);
    commit('UPDATE_MARKETING_CAMPAIGN_MANAGER', { campaigns: [campaign] });

    await new Promise((resolve) => { setTimeout(resolve, 3000); });

    const settings = await grpcClient.getMarketingCampaignSettings();
    commit('UPDATE_MARKETING_CAMPAIGN_MANAGER', { settings });

    return campaign;
  },
  async archiveMarketingCampaign({ commit, rootGetters }, p: { campaign: MarketingCampaign }) {
    const campaign = await grpcClient.archiveMarketingCampaign(p.campaign, rootGetters as MarketingCampaignMapping);
    commit('UPDATE_MARKETING_CAMPAIGN_MANAGER', { campaigns: [campaign] });
    return campaign;
  },
  async deleteMarketingCampaign({ commit, rootGetters }, p: { campaign: MarketingCampaign }) {
    const campaign = await grpcClient.deleteMarketingCampaign(p.campaign, rootGetters as MarketingCampaignMapping);
    commit('UPDATE_MARKETING_CAMPAIGN_MANAGER', { campaigns: [campaign] });
    return campaign;
  },
  async testMarketingCampaign({ commit, rootGetters }, p: { campaign: MarketingCampaign, recipients: string[] }) {
    await grpcClient.testMarketingCampaign(p.campaign, p.recipients);
    return true;
  },
  async previewContactFilterForMarketingCampaign({ dispatch, commit, rootGetters }, p: { contactFilter: ContactFilter }) {
    const stats = await grpcClient.previewContactFilterForMarketingCampaign(p.contactFilter);
    return stats.matching;
  },

  // contact filter
  async crudMarketingCampaignManagerContactFilter(
    { commit, rootState, rootGetters },
    p: { contactFilter: ContactFilter, errorFnc: (e: any) => void, operation: string },
  ): Promise<ContactFilter|null> {
    if ((grpcClient as any)[p.operation] === undefined) throw new Error(`grpc operation '${p.operation}' error: not implemented`);
    if (!rootGetters.isLoaded) throw new Error(`grpc operation '${p.operation}' error: not loaded`);

    const contactFilter = await (grpcClient as any)[p.operation](
      p.contactFilter,
    );
    commit('UPDATE_MARKETING_CAMPAIGN_MANAGER', { contactFilters: [contactFilter] });

    return contactFilter;
  },
  async markeingCampaignManagerCreateContactFilter({ dispatch }, p: any) { return dispatch('crudMarketingCampaignManagerContactFilter', { ...p, operation: 'createContactFilter' }); },
  async markeingCampaignManagerGetContactFilter({ dispatch }, p: any) { return dispatch('crudMarketingCampaignManagerContactFilter', { ...p, operation: 'getContactFilter' }); },
  async markeingCampaignManagerUpdateContactFilter({ dispatch }, p: any) { return dispatch('crudMarketingCampaignManagerContactFilter', { ...p, operation: 'updateContactFilter' }); },
  async markeingCampaignManagerDeleteContactFilter({ dispatch }, p: any) { return dispatch('crudMarketingCampaignManagerContactFilter', { ...p, operation: 'deleteContactFilter' }); },

  // html templates
  async crudMarketingCampaignManagerHtmlTemplate(
    { commit, rootGetters },
    p: { htmlTemplate: HtmlTemplate, errorFnc: (e: any) => void, operation: string },
  ): Promise<HtmlTemplate|null> {
    if ((grpcClient as any)[p.operation] === undefined) throw new Error(`grpc operation '${p.operation}' error: not implemented`);
    if (!rootGetters.isLoaded) throw new Error(`grpc operation '${p.operation}' error: not loaded`);

    const htmlTemplate = await (grpcClient as any)[p.operation](p.htmlTemplate);
    commit('UPDATE_MARKETING_CAMPAIGN_MANAGER', { htmlTemplates: [htmlTemplate] });

    return htmlTemplate;
  },
  async marketingCampaignManagerCreateHtmlTemplate({ dispatch }, p: any) { return dispatch('crudMarketingCampaignManagerHtmlTemplate', { ...p, operation: 'createHtmlTemplate' }); },
  async marketingCampaignManagerGetHtlTemplate({ dispatch }, p: any) { return dispatch('crudMarketingCampaignManagerHtmlTemplate', { ...p, operation: 'getHtmlTemplate' }); },
  async marketingCampaignManagerUpdateHtmlTemplate({ dispatch }, p: any) { return dispatch('crudMarketingCampaignManagerHtmlTemplate', { ...p, operation: 'updateHtmlTemplate' }); },
  async marketingCampaignManagerDeleteHtmlTemplate({ dispatch }, p: any) { return dispatch('crudMarketingCampaignManagerHtmlTemplate', { ...p, operation: 'deleteHtmlTemplate' }); },
};

const getters = <GetterTree<IMarketingCampaignManagerState, IRootState>>{
  isMarketingCampaignManagerLoaded(state: MarketingCampaignManagerState, localGetters: any, rootState: any, rootGetters: any): boolean {
    return state.isLoaded;
  },
  areMarketingCampaignManagerCampaignsLoaded(state: MarketingCampaignManagerState, localGetters: any, rootState: any, rootGetters: any): boolean {
    return state.areCampaignsLoaded;
  },
  marketingCampaignManagerFilter(state) {
    return state.campaignFilter;
  },
  marketingCampaignManagerCampaigns(state: MarketingCampaignManagerState, localGetters: any, rootState: any, rootGetters: any): MarketingCampaign[] {
    return state.campaigns;
  },
  marketingCampaignManagerContactFilters(state: MarketingCampaignManagerState, localGetters: any, rootState: any, rootGetters: any): ContactFilter[] {
    return state.contactFilters;
  },
  marketingCampaignManagerHtmlTemplates(state: MarketingCampaignManagerState, localGetters: any, rootState: any, rootGetters: any): HtmlTemplate[] {
    return state.htmlTemplates;
  },
  marketingCampaignSettings(state: MarketingCampaignManagerState, localGetters: any, rootState: any, rootGetters: any): MarketingCampaignSettings {
    return state.settings;
  },
};

const MarketingCampaignManagerStore = {
  namespaced: false,
  state: new MarketingCampaignManagerState(),
  mutations,
  actions,
  getters,
};

export default MarketingCampaignManagerStore;
