/* eslint-disable max-len */
/* eslint-disable vue/max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */

import { TranslateResult } from 'vue-i18n';
import i18n from '@/plugins/i18n';
import {
  FilterFieldRule, FilterFieldType, FilterRule, FilterRuleType,
} from './filter-rule';
import {
  FilterDateNoYearOperands,
  FilterDateNoYearOperandsWithSeparators, FilterDateOperand, FilterDateOperands, FilterDateOperandsWithSeparators,
} from './filter-rule-date-operation';
import { FilterOperandSeparator, FilterOperandType, isEmptyOperand } from './filter-rule-operation';

export type ListInfo<T> = { text: TranslateResult, value: T, divider?: boolean }[]

export enum FilterSpecialCategoryOperandType {
  Country = 'country',
  Language = 'language',
}

const FilterSpecialCategoryOperandTypes = [FilterSpecialCategoryOperandType.Country, FilterSpecialCategoryOperandType.Language];

export const FilterStringRuleTypes = [FilterRuleType.Equal, FilterRuleType.NotEqual, FilterRuleType.Contains, FilterRuleType.IsNotEmpty, FilterRuleType.IsEmpty];
export const FilterNumberRuleTypes = [FilterRuleType.Equal, FilterRuleType.NotEqual, FilterRuleType.LessThen, FilterRuleType.GreaterThen, FilterRuleType.IsNotEmpty, FilterRuleType.IsEmpty];
export const FilterBooleanRuleTypes = [FilterRuleType.IsSet, FilterRuleType.IsNotSet];
export const FilterDateRuleTypes = [FilterRuleType.Equal, FilterRuleType.NotEqual, FilterRuleType.IsNotEmpty, FilterRuleType.IsEmpty];
export const FilterCathegoricalRuleTypes = [FilterRuleType.Equal, FilterRuleType.NotEqual, FilterRuleType.IsNotEmpty, FilterRuleType.IsEmpty];

export class FilterDescriptor {
  ruleDescriptors: FilterRuleDescriptor[] = [];

  constructor(ruleDescriptors: FilterRuleDescriptor[]) {
    this.ruleDescriptors = ruleDescriptors;
  }

  validateRule(rule: FilterFieldRule, checkOperand = true): TranslateResult | null {
    if (rule.field.trim() === '') return i18n.t('filter.message.rule_field_not_specified');
    const ruleDescriptor = this.ruleDescriptors.find((rd) => rd.field === rule.field);
    if (!ruleDescriptor) return i18n.t('filter.message.rule_field_not_allowed', { field: rule.field });
    return ruleDescriptor.validateRule(rule, checkOperand);
  }

  allowedFieldsInfo(): ListInfo<string> {
    return this.ruleDescriptors.map((rd) => ({ text: rd.fieldText, value: rd.field }));
  }
}

export class FilterRuleDescriptor {
  id: number = 0;

  field: string = '';

  fieldText: string = '';

  fieldtype: FilterFieldType = FilterFieldType.None;

  allowedRuleTypes: FilterRuleType[] = [];

  allowedOperands: FilterOperandType[] | null | FilterSpecialCategoryOperandType = null;

  constructor(field: string, fieldText: string, fieldtype: FilterFieldType, ruleTypes: FilterRuleType[], operands?: FilterOperandType[] | FilterSpecialCategoryOperandType) {
    this.field = field;
    this.fieldText = fieldText;
    this.fieldtype = fieldtype;
    this.allowedRuleTypes = ruleTypes;
    this.allowedOperands = operands ?? null;

    if (fieldtype === FilterFieldType.Date && !this.allowedOperands) { this.allowedOperands = FilterDateOperandsWithSeparators; }
    if (fieldtype === FilterFieldType.DateNoYear && !this.allowedOperands) { this.allowedOperands = FilterDateNoYearOperandsWithSeparators; }
  }

  // static StringFilterRuleDescriptor(field: string, fieldText: string, ruleTypes: FilterRuleType[], operands?: FilterOperandType[]) {
  //   return new FilterRuleDescriptor(field, fieldText, FilterFieldType.String, ruleTypes, operands);
  // }

  static StringFilterRuleDescriptor(field: string, fieldText: string) {
    return new FilterRuleDescriptor(field, fieldText, FilterFieldType.String, FilterStringRuleTypes);
  }

  static BoolFilterRuleDescriptor(field: string, fieldText: string) {
    return new FilterRuleDescriptor(field, fieldText, FilterFieldType.Boolean, FilterBooleanRuleTypes);
  }

  static DateFilterRuleDescriptor(field: string, fieldText: string) {
    return new FilterRuleDescriptor(field, fieldText, FilterFieldType.Date, FilterDateRuleTypes);
  }

  static NumberFilterRuleDescriptor(field: string, fieldText: string) {
    return new FilterRuleDescriptor(field, fieldText, FilterFieldType.Number, FilterNumberRuleTypes);
  }

  validateRule(rule: FilterFieldRule, checkOperand = true): TranslateResult | null {
    if (rule.field === '') return i18n.t('filter.message.rule_field_not_specified');
    if (this.field !== rule.field) i18n.t('filter.message.rule_field_not_allowed', { field: this.fieldText });

    if (rule.type === FilterRuleType.None) return i18n.t('filter.message.rule_operation_not_specified', { field: this.fieldText });
    if (!this.allowedRuleTypes.includes(rule.type)) return i18n.t('filter.message.rule_operation_not_allowed', { field: this.fieldText });

    // no operand check when empty while editing
    if (!checkOperand && isEmptyOperand(rule.operand)) return null;

    return this.validateOperand(rule);
  }

  validateOperand(rule: FilterFieldRule): TranslateResult | null {
    if (FilterRule.isFilterFieldNoOperandType(rule.type)) {
      if (rule.operand === undefined || rule.operand === null) return null;
      return i18n.t('filter.message.rule_value_not_allowed', { field: this.fieldText });
    }

    if (isEmptyOperand(rule.operand)) return i18n.t('filter.message.rule_value_not_specified', { field: this.fieldText });
    if (this.allowedOperands) {
      if (typeof this.allowedOperands === 'string') {
        if (this.specialCategoryOperandsType() === null) return i18n.t('filter.message.rule_value_not_allowed', { field: this.fieldText }); // should be special operand
      } else if (!this.allowedOperands.includes(rule.operand)) return i18n.t('filter.message.rule_value_not_allowed', { field: this.fieldText }); // should be included in allowed options
    } else {
      if (FilterRuleDescriptor.isSpecialOperand(rule.operand)) return i18n.t('filter.message.rule_value_not_allowed', { field: this.fieldText });

      switch (this.fieldtype) {
        case FilterFieldType.Boolean: return i18n.t('filter.message.rule_value_not_allowed', { field: this.fieldText }); break;

        case FilterFieldType.String:
          if (typeof rule.operand !== 'string') return i18n.t('filter.message.rule_value_not_allowed', { field: this.fieldText }); break;
        case FilterFieldType.Number:
          if (typeof rule.operand !== 'number') return i18n.t('filter.message.rule_value_not_allowed', { field: this.fieldText }); break;

        case FilterFieldType.Date:
          if (!FilterDateOperands.includes(rule.operand as FilterDateOperand)) return i18n.t('filter.message.rule_value_not_allowed', { field: this.fieldText }); break;
        case FilterFieldType.DateNoYear:
          if (!FilterDateNoYearOperands.includes(rule.operand as FilterDateOperand)) return i18n.t('filter.message.rule_value_not_allowed', { field: this.fieldText }); break;

        default: return i18n.t('filter.message.rule_value_not_allowed', { field: this.fieldText }); break;
      }
    }

    return null;
  }

  static isSpecialOperand(operand: FilterOperandType) {
    if (typeof operand !== 'string') return false;
    return operand.startsWith('@@') && operand.endsWith('@@');
  }

  allowedRuleTypesInfo(): ListInfo<FilterRuleType> {
    return this.allowedRuleTypes.map((rt) => ({ text: FilterRuleDescriptor.ruleTypeText(this.fieldtype, rt), value: rt }));
  }

  specialCategoryOperandsType(): FilterSpecialCategoryOperandType | null {
    if (typeof this.allowedOperands !== 'string' || !FilterSpecialCategoryOperandTypes.includes(this.allowedOperands)) return null;
    return this.allowedOperands; // single string means special category
  }

  allowedOperandsInfo(): ListInfo<FilterOperandType> | null {
    if (!this.allowedOperands) return null;
    if (typeof this.allowedOperands === 'string') return null;

    return this.allowedOperands.map((o) => ({ text: FilterRuleDescriptor.operandText(this.fieldtype, o), value: o, divider: o === FilterOperandSeparator ? true : undefined }));
  }

  static operandText(fieldType: FilterFieldType, operand: FilterOperandType): TranslateResult {
    if (fieldType === FilterFieldType.Date || fieldType === FilterFieldType.DateNoYear) {
      switch (operand) {
        case FilterDateOperand.Today: return i18n.t('code.date_range.today');
        case FilterDateOperand.Tomorrow: return i18n.t('code.date_range.tomorrow');

        case FilterDateOperand.Yesterday: return i18n.t('code.date_range.yesterday');
        case FilterDateOperand.ThisWeek: return i18n.t('code.date_range.this_week');
        case FilterDateOperand.NextWeek: return i18n.t('code.date_range.next_week');
        case FilterDateOperand.PreviousWeek: return i18n.t('code.date_range.last_week');
        case FilterDateOperand.ThisMonth: return i18n.t('code.date_range.this_month');
        case FilterDateOperand.NextMonth: return i18n.t('code.date_range.next_month');

        case FilterDateOperand.PreviousMonth: return i18n.t('code.date_range.last_month');
        case FilterDateOperand.ThisYear: return i18n.t('code.date_range.this_year');
        case FilterDateOperand.NextYear: return i18n.t('code.date_range.next_year');
        case FilterDateOperand.PreviousYear: return i18n.t('code.date_range.last_year');
        default: return String(operand);
      }
    }

    if (!operand) return '';
    return String(operand);
  }

  static dateOperandText(fieldType: FilterFieldType, operand: FilterOperandType): string {
    if (!operand) return '';
    return String(operand);
  }

  static ruleTypeText(fieldType: FilterFieldType, ruleType: FilterRuleType): TranslateResult {
    const textop = ([FilterFieldType.String, FilterFieldType.Date, FilterFieldType.DateNoYear].includes(fieldType));

    switch (ruleType) {
      case FilterRuleType.Equal: return textop ? i18n.t('filter.rule_type.equal') : '=';
      case FilterRuleType.NotEqual: return textop ? i18n.t('filter.rule_type.not_equal') : '<>';
      case FilterRuleType.Contains: return i18n.t('filter.rule_type.contains');
      case FilterRuleType.LessThen: return '<';
      case FilterRuleType.GreaterThen: return '>';
      case FilterRuleType.IsSet: return i18n.t('filter.rule_type.is_set');
      case FilterRuleType.IsNotSet: return i18n.t('filter.rule_type.is_not_set');
      case FilterRuleType.IsNotEmpty: return i18n.t('filter.rule_type.is_not_empty');
      case FilterRuleType.IsEmpty: return i18n.t('filter.rule_type.is_empty');
      default: return fieldType as string;
    }
  }
}
