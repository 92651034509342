import i18n from '@/plugins/i18n';
import { TranslateResult } from 'vue-i18n';

export interface MenuItem {
  icon?: string,
  filledIcon?: string,
  text: string|TranslateResult,
  link?:string,
  labelBadge?: string,
  subitems?: MenuItem[]
  disabled?:boolean
}
export function reportsMenuItems(): MenuItem[] {
  const reportsMenu = [
    { text: i18n.t('title.reservations'), link: '/report/reservations' },
    { text: i18n.t('title.weekdays'), link: '/report/weekdays' },
    { text: i18n.t('title.services'), link: '/report/services' },
    { text: i18n.t('title.feedback'), link: '/report/feedback' },
    { text: i18n.t('title.messages'), link: '/report/messages' },
  ];

  return reportsMenu;
}

export function newsletterMenuItems(): MenuItem[] {
  return [
    { text: i18n.t('title.newsletter_campaigns'), link: '/newsletter/campaigns' },
    { text: i18n.t('title.newsletter_library'), link: '/newsletter/library' },
  ];
}

export function reservationMenuItems(): MenuItem[] {
  return [
    {
      icon: 'mdi-account-group-outline',
      filledIcon: 'mdi-account-group',
      text: i18n.t('title.guests'),
      link: '/guests',
    },
    { icon: 'mdi-chart-timeline', text: i18n.t('title.tables'), link: '/tables' },
    { icon: 'mdi-table-chair', text: i18n.t('label.floor_plan'), link: '/floor' },
  ];
}

export function settingsMenuItems(p: { billingPageAllowed?: boolean}): MenuItem[] {
  const settingsMenu = [
    {
      text: i18n.t('title.account'),
      subitems: [
        { text: i18n.t('title.general'), link: '/settings/general' },
      ],
    },

    { text: i18n.t('title.users'), link: '/settings/users' },
    { text: i18n.t('title.sections'), link: '/settings/sections' },
    { text: i18n.t('title.online_reservations'), link: '/settings/online' },
    {
      text: i18n.t('title.opening_hours'),
      subitems: [
        { text: i18n.t('title.set_opening_hours'), link: '/settings/hours' },
        { text: i18n.t('title.set_special_hours'), link: '/settings/special-hours' },
      ],
    },
    { text: i18n.t('title.service_manager'), link: '/settings/service-manager' },
    { text: i18n.t('title.feedback'), link: '/settings/feedback' },
    {
      text: i18n.t('title.message_center'),
      subitems: [
        { text: i18n.t('title.messages'), link: '/settings/message-center' },
        { text: i18n.t('title.sms'), link: '/settings/sms' },
      ],
    },
    {
      text: i18n.t('title.preferences'),
      subitems: [
        { text: i18n.t('title.manage_employee_names'), link: '/settings/employees' },
        { text: i18n.t('title.manage_tags'), link: '/settings/tagnotes' },
        { text: i18n.t('title.manage_reservation_labels'), link: '/settings/labels' },
        { text: i18n.t('title.advanced_settings'), link: '/settings/advanced' },
      ],
    },
  ];

  if (p.billingPageAllowed) {
    settingsMenu[0].subitems?.push({ text: i18n.t('title.billing'), link: '/settings/billing' });
    // settingsMenu[6].subitems?.push({ text: i18n.t('title.sms_notifications'), link: '/settings/sms' });
  }

  return settingsMenu;
}
