/* eslint-disable max-len */
/* eslint-disable vue/max-len */

import * as pbm from '@/grpc-pb/marketing_campaign_pb';
import * as pbs from '@/grpc-pb/marketing_campaign_manager_service_pb';
import { DateRange, dateRangeFromPeriod, DateRangePeriod } from '@/services/date-range';
import ContactFilter from './contact-filter';

export enum MarketingCampaignStatus {
  None = 'none',
  Draft = 'draft',
  Scheduled = 'scheduled',
  Processing = 'processing',
  Sent = 'sent',
  Abandoned = 'abandoned',
  Failure = 'failure'
}

export interface MarketingCampaignMapping {
  marketingCampaignManagerContactFilters: ContactFilter[];
}

export default class MarketingCampaign {
  id: number = 0;

  locale?: string | null;

  title?: string | null;

  subject?: string | null;

  previewText?: string | null;

  contactFilter: ContactFilter | null = null;

  templateName?: string | null;

  templateDesign?: string | null;

  templateHtml?: string | null;

  templatePlaintext?: string | null;

  templatePreview?: string | null;

  status?: MarketingCampaignStatus = MarketingCampaignStatus.Draft;

  dtCreate?: Date;

  dtUpdate?: Date;

  dtSend?: Date | null;

  dtProcessed?: Date | null;

  isArchived?: boolean;

  isDeleted?: boolean;

  audienceStats?: {
    matching?: number;
    all?: number;
  };

  publishStats?: {
    scheduled?: number;
    sent?: number;
    processing?: number;
    abandoned?: number;
    failure?: number;
    all?: number;
  };

  deliveryStats?: {
    processed? : number;
    dropped? : number;
    delivered? : number;
    deferred? : number;
    bounce? : number;
    blocked? : number;
  };

  engagementStats?: {
    open?: number;
    unique_open?: number;
    click?: number;
    unique_click?: number;
    spam?: number;
    unsubscribe?: number;
    group_unsubscribe?: number;
    group_resubscribe?: number;

    widget_open?: number;
    unique_widget_open?: number;
    widget_reservation?: number;
    unique_widget_reservation?: number;
    widget_unsubscribe?: number;

  };

  conversionStats?: {
    all?: number;
    imminent?: number;
    unique_all?: number;
    unique_imminent?: number;
    guests_all?: number;
    guests_imminent?: number;
  };

  get date(): Date { return this.dtSend ?? this.dtCreate!; }

  get canArchive(): boolean { return [MarketingCampaignStatus.Sent, MarketingCampaignStatus.Abandoned, MarketingCampaignStatus.Failure].includes(this.status!); }

  get canDelete(): boolean { return [MarketingCampaignStatus.Draft].includes(this.status!); }

  get recipientCount(): number | undefined { return [MarketingCampaignStatus.Draft].includes(this.status!) ? this.audienceStats?.matching : this.publishStats?.sent; }

  static fromGrpcResponse(r: pbs.MarketingCampaignResponse, m?: MarketingCampaignMapping): MarketingCampaign {
    const o = r.getMarketingCampaign();
    return MarketingCampaign.fromGrpcModel(o, m);
  }

  static fromGrpcModel(o?: pbm.MarketingCampaign, m?: MarketingCampaignMapping): MarketingCampaign {
    const statuses = [
      MarketingCampaignStatus.None,
      MarketingCampaignStatus.Draft,
      MarketingCampaignStatus.Scheduled,
      MarketingCampaignStatus.Processing,
      MarketingCampaignStatus.Sent,
      MarketingCampaignStatus.Abandoned,
      MarketingCampaignStatus.Failure,
    ];

    const mc = new MarketingCampaign();

    mc.id = o?.getId() ?? 0;
    mc.locale = o?.getLocale() ?? null;
    mc.status = statuses[o?.getStatus() ?? 0];
    mc.title = o?.getTitle() ?? null;
    mc.subject = o?.getSubject() ?? null;
    mc.previewText = o?.getPreviewText() ?? null;
    mc.contactFilter = m?.marketingCampaignManagerContactFilters.find((cf) => cf.id === o?.getContactFilterId()) ?? null;
    mc.templateName = o?.getTemplateName() ?? null;
    mc.templateDesign = o?.getTemplateDesign() ?? null;
    mc.templateHtml = o?.getTemplateHtml() ?? null;
    mc.templatePlaintext = o?.getTemplatePlaintext() ?? null;
    mc.templatePreview = o?.getTemplatePreview() ?? null;
    mc.dtCreate = o?.getDtCreate() ? new Date(o.getDtCreate() * 1000) : new Date();
    mc.dtUpdate = o?.getDtUpdate() ? new Date(o.getDtUpdate() * 1000) : new Date();
    mc.dtSend = o?.getDtSend() ? new Date(o.getDtSend() * 1000) : null;
    mc.dtProcessed = o?.getDtProcessed() ? new Date(o.getDtProcessed() * 1000) : null;
    mc.isArchived = o?.getIsArchived();
    mc.isDeleted = o?.getIsDeleted();

    const astats = o?.getAudienceStats();
    if (astats) {
      mc.audienceStats = {
        all: astats.getAll(),

        matching: astats.getMatching(),
      };
    }

    const pstats = o?.getPublishStats();
    if (pstats) {
      mc.publishStats = {
        all: pstats.getAll(),
        scheduled: pstats.getScheduled(),
        sent: pstats.getSent(),
        processing: pstats.getProcessing(),
        abandoned: pstats.getAbandoned(),
        failure: pstats.getFailure(),
      };
    }

    const dstats = o?.getDeliveryStats();
    if (dstats) {
      mc.deliveryStats = {
        processed: dstats.getProcessed(),
        dropped: dstats.getDropped(),
        delivered: dstats.getDelivered(),
        deferred: dstats.getDeferred(),
        bounce: dstats.getBounce(),
        blocked: dstats.getBlocked(),
      };
    }

    const estats = o?.getEngagementStats();
    if (estats) {
      mc.engagementStats = {
        open: estats.getOpen(),
        unique_open: estats.getUniqueOpen(),
        click: estats.getClick(),
        unique_click: estats.getUniqueClick(),
        spam: estats.getSpam(),
        unsubscribe: estats.getUnsubscribe(),
        group_unsubscribe: estats.getGroupUnsubscribe(),
        group_resubscribe: estats.getGroupResubscribe(),

        widget_open: estats.getWidgetOpen(),
        unique_widget_open: estats.getUniqueWidgetOpen(),
        widget_reservation: estats.getWidgetReservation(),
        unique_widget_reservation: estats.getUniqueWidgetReservation(),
        widget_unsubscribe: estats.getWidgetUnsubscribe(),
      };
    }

    const cstats = o?.getConversionStats();
    if (cstats) {
      mc.conversionStats = {
        all: cstats.getAll(),
        imminent: cstats.getImminent(),
        unique_all: cstats.getUniqueAll(),
        unique_imminent: cstats.getUniqueImminent(),
        guests_all: cstats.getGuestsAll(),
        guests_imminent: cstats.getGuestsImminent(),
      };
    }

    return mc;
  }

  toGrpcModel(): pbm.MarketingCampaign {
    const o = new pbm.MarketingCampaign();

    o.setId(this.id);
    if (this.locale) o.setLocale(this.locale);
    if (this.title) o.setTitle(this.title);
    if (this.subject) o.setSubject(this.subject);
    if (this.previewText) o.setPreviewText(this.previewText);
    if (this.contactFilter) {
      o.setContactFilterId(this.contactFilter.id);
      o.setContactFilterName(this.contactFilter.name);
      o.setContactFilterData(this.contactFilter.data ?? '');
    }
    if (this.templateName) o.setTemplateName(this.templateName);
    if (this.templateDesign) o.setTemplateDesign(this.templateDesign);
    if (this.templateHtml) o.setTemplateHtml(this.templateHtml);
    if (this.templatePlaintext) o.setTemplatePlaintext(this.templatePlaintext);

    return o;
  }

  static compare(o1: MarketingCampaign, o2: MarketingCampaign): number {
    const d1 = o1.dtSend ?? o1.dtCreate;
    const d2 = o2.dtSend ?? o2.dtCreate;

    const res = d2!.getTime() - d1!.getTime();
    if (res !== 0) return res;
    return o2.id - o1.id;
  }

  clone(): MarketingCampaign {
    const copy = new MarketingCampaign();
    Object.assign(copy, this);
    return copy;
  }
}

export interface MarketingCampaignListParams {
  beginDateIndex: number,
  endDateIndex: number,
  showArchived: boolean,
}

export interface MarketingCampaignFilter {
  dateRange: DateRange;

  showArchived: boolean
}

export function defaultMarketingCampaignFilter(): MarketingCampaignFilter {
  return {
    dateRange: dateRangeFromPeriod(DateRangePeriod.Last90Days),
    showArchived: false,
  };
}

export function areMarketingCampaignFiltersSame(o1: MarketingCampaignFilter, o2: MarketingCampaignFilter): boolean {
  if (o1.dateRange.beginDate.getTime() !== o2.dateRange.beginDate.getTime()) return false;
  if (o1.dateRange.endDate.getTime() !== o2.dateRange.endDate.getTime()) return false;
  if (o1.showArchived !== o2.showArchived) return false;
  return true;
}
