import * as pbm from '@/grpc-pb/contact_filter_pb';
import * as pbs from '@/grpc-pb/contact_manager_service_pb';
import i18n from '@/plugins/i18n';
import { Filter } from '@/services/filter/filter';
import { FilterConvert } from '@/services/filter/filter-convert';
import {
  FilterDescriptor, FilterRuleDescriptor,
  FilterBooleanRuleTypes, FilterDateRuleTypes, FilterNumberRuleTypes, FilterStringRuleTypes,
  FilterCathegoricalRuleTypes, FilterSpecialCategoryOperandType,
} from '@/services/filter/filter-descriptor';
import { FilterFieldType, FilterRule, FilterRuleType } from '@/services/filter/filter-rule';
import { FilterDateOperand, FilterDateOperands } from '@/services/filter/filter-rule-date-operation';

export default class ContactFilter {
  id: number = 0;

  order: number = 0;

  name: string = '';

  data?: string;

  isDeleted?: boolean;

  get isDefault() { return this.order < 0; }

  get value() { return this.id; }

  get text() {
    if (this.name.startsWith('title.filter_contacts_')) {
      const text = i18n.tc(this.name);
      if (this.name !== text) return text;
    }
    return this.name;
  }

  constructor(o?: { id?: number, order?: number; name: string, data: string }) {
    this.id = o?.id ?? 0;
    this.order = o?.order ?? 0;
    this.name = o?.name ?? '';
    this.data = o?.data ?? '';
  }

  static compare(o1: ContactFilter, o2: ContactFilter): number {
    const res = o1.order - o2.order;
    if (res !== 0) return res;
    return o1.id - o2.id;
  }

  static fromGrpcResponse(r: pbs.ContactFilterListResponse): ContactFilter {
    const e = r.getContactfilter();
    return ContactFilter.fromGrpcModel(e);
  }

  static fromGrpcModel(o?: pbm.ContactFilter): ContactFilter {
    const e = new ContactFilter();

    e.id = o?.getId() ?? 0;
    e.order = o?.getOrder() ?? 0;
    e.name = o?.getName() || 'N/A';
    e.data = o?.getData() ?? '';
    e.isDeleted = o?.getIsdeleted();

    return e;
  }

  toGrpcModel(): pbm.ContactFilter {
    const o = new pbm.ContactFilter();

    o.setId(this.id);
    o.setOrder(this.order);
    o.setName(this.name);
    if (this.data) o.setData(this.data);
    if (this.isDeleted) o.setIsdeleted(this.isDeleted);

    return o;
  }

  static readonly FilterDescriptor = new FilterDescriptor([
    FilterRuleDescriptor.StringFilterRuleDescriptor('name', i18n.tc('label.name')),
    FilterRuleDescriptor.StringFilterRuleDescriptor('phone', i18n.tc('label.phone')),
    FilterRuleDescriptor.StringFilterRuleDescriptor('companyName', i18n.tc('label.company_name')),
    FilterRuleDescriptor.StringFilterRuleDescriptor('email', i18n.tc('label.email')),
    new FilterRuleDescriptor(
      'visitCount',
      i18n.tc('label.visits'),
      FilterFieldType.Number,
      FilterNumberRuleTypes,
      [1, 5, 10, 20, 50, 100],
    ),
    FilterRuleDescriptor.BoolFilterRuleDescriptor('isVIP', i18n.tc('label.vip')),
    FilterRuleDescriptor.BoolFilterRuleDescriptor('newsletterSubscription', i18n.tc('label.newsletter_subscription')),
    FilterRuleDescriptor.DateFilterRuleDescriptor('lastVisit', i18n.tc('label.last_visit')),
    new FilterRuleDescriptor('dateOfBirth', i18n.tc('label.birthday'), FilterFieldType.DateNoYear, FilterDateRuleTypes),
    FilterRuleDescriptor.NumberFilterRuleDescriptor('reservationCount', i18n.tc('label.visits')),
    new FilterRuleDescriptor(
      'preferredLanguage',
      i18n.tc('label.language'),
      FilterFieldType.String,
      FilterCathegoricalRuleTypes,
      FilterSpecialCategoryOperandType.Language,
    ),
    FilterRuleDescriptor.StringFilterRuleDescriptor('notes', i18n.tc('label.notes')),
    FilterRuleDescriptor.StringFilterRuleDescriptor('title', i18n.tc('label.title')),
    new FilterRuleDescriptor(
      'country',
      i18n.tc('label.country'),
      FilterFieldType.String,
      FilterCathegoricalRuleTypes,
      FilterSpecialCategoryOperandType.Country,
    ),
    FilterRuleDescriptor.StringFilterRuleDescriptor('address', i18n.tc('label.address')),
    FilterRuleDescriptor.StringFilterRuleDescriptor('postalCode', i18n.tc('label.zip_code')),
    FilterRuleDescriptor.StringFilterRuleDescriptor('city', i18n.tc('label.city')),
    FilterRuleDescriptor.StringFilterRuleDescriptor('state', i18n.tc('label.state')),
    FilterRuleDescriptor.DateFilterRuleDescriptor('updated', i18n.tc('label.updated')),
  ]);

  static readonly AllContactFilter = new ContactFilter({
    id: -10,
    order: -10,
    name: 'title.filter_contacts_all',
    data: FilterConvert.toJson(
      Filter.newFilterFrom3Rules({
        defineRules: [
          // FilterRule.newFilterFieldRule(FilterRuleType.IsNotEmpty, 'name', FilterFieldType.String)!,
          // FilterRule.newFilterFieldRule(FilterRuleType.IsNotEmpty, 'phone', FilterFieldType.String)!,
          // FilterRule.newFilterFieldRule(FilterRuleType.IsNotEmpty, 'companyName', FilterFieldType.String)!,
        ],
        narrowRules: [
        ],
        excludeRules: [
        ],
      }),
    ),
  });

  static readonly EmptyContactFilter = new ContactFilter({
    id: -9,
    order: -9,
    name: 'title.filter_contacts_without_information',
    data: FilterConvert.toJson(
      Filter.newFilterFrom3Rules({
        defineRules: [],
        narrowRules: [
          FilterRule.newFilterFieldRule(FilterRuleType.IsEmpty, 'name', FilterFieldType.String)!,
          FilterRule.newFilterFieldRule(FilterRuleType.IsEmpty, 'phone', FilterFieldType.String)!,
          FilterRule.newFilterFieldRule(FilterRuleType.IsEmpty, 'email', FilterFieldType.String)!,
          FilterRule.newFilterFieldRule(FilterRuleType.IsEmpty, 'companyName', FilterFieldType.String)!,
        ],
        excludeRules: [],
      }),
    ),
  });

  static readonly RecentContactFilter = new ContactFilter({
    id: -8,
    order: -8,
    name: 'title.filter_contacts_recently_visited',
    data: FilterConvert.toJson(
      Filter.newFilterFrom3Rules({
        defineRules: [
          FilterRule.newFilterFieldRule(
            FilterRuleType.Equal,
            'lastVisit',
            FilterFieldType.Date,
            FilterDateOperand.ThisMonth,
          )!,
          FilterRule.newFilterFieldRule(
            FilterRuleType.Equal,
            'lastVisit',
            FilterFieldType.Date,
            FilterDateOperand.PreviousMonth,
          )!,
        ],
        narrowRules: [],
        excludeRules: [],
      }),
    ),
  });

  static readonly EmailNotEmptyContactFilter = new ContactFilter({
    id: -7,
    order: -7,
    name: 'title.filter_contacts_with_email',
    data: FilterConvert.toJson(
      Filter.newFilterFrom3Rules({
        defineRules: [
          FilterRule.newFilterFieldRule(FilterRuleType.IsNotEmpty, 'email', FilterFieldType.String)!,
        ],
        narrowRules: [],
        excludeRules: [],
      }),
    ),
  });

  static readonly DefaultFilters = [
    ContactFilter.AllContactFilter,
    ContactFilter.EmptyContactFilter,
    ContactFilter.RecentContactFilter,
    ContactFilter.EmailNotEmptyContactFilter,
  ];

  static readonly DefaultFilter = ContactFilter.AllContactFilter;
}
