/* eslint-disable max-len */
/* eslint-disable vue/max-len */
import { GetterTree, MutationTree, ActionTree } from 'vuex';
import grpcClient from '@/grpc-api/grpc-client';
import Contact, { ContactSortFncType, ContactSortType } from '@/grpc-api/model/contact';
import ContactFilter from '@/grpc-api/model/contact-filter';
import { Filter } from '@/services/filter/filter';
import {
  addToCountMap, deleteModelEntities, mergeSortedModelEntities, removeFromCountMap,
} from '@/grpc-api/model/model-utils';
import { FilterConvert } from '@/services/filter/filter-convert';
import { OnErrorFnc } from '@/services/grpc-client-utils';
import { RpcError } from 'grpc-web';
import { ContactMapping } from '@/model/Contact';
import httpClient from '@/api/http-client';
import Reservation, { ReservationMapping } from '@/model/Reservation';
import Employee from '@/grpc-api/model/employee';
import { cloneModel, toMapById } from '@/model/model-utils';
import {
  searchContactRemove, searchContactReset, searchContactAdd, searchForContacts,
} from '@/services/contact-search';
import { dialCodeByCountry } from '@/model/Country';
import { ApiError } from '@/api/api-error';
import IRootState, { IGuestbookState } from './store-state';

export class GuestbookState implements IGuestbookState {
  contacts: Contact[] = [];

  filteredContacts: Contact[] = [];

  filteredContactsById: Map<number, Contact> = new Map<number, Contact>();

  duplicateContactCountsByEmail: Map<string, number> = new Map<string, number>();

  duplicateContactCountsByPhone: Map<string, number> = new Map<string, number>();

  contactFilters: ContactFilter[] = [];

  contactFilter: ContactFilter = ContactFilter.DefaultFilter;

  filter: Filter | null = FilterConvert.fromJson(ContactFilter.DefaultFilter.data!);

  filterError: Error | null = null;

  isLoaded: boolean = false;

  isFullyLoaded: boolean = false;

  visiblePageStart: number = 0;

  visiblePageSize: number = 25;

  contactSortType: ContactSortType = ContactSortType.VisitCount;

  contactSortDesc: boolean = true;

  // eslint-disable-next-line class-methods-use-this
  contactCompareFnc: ContactSortFncType = (o1: Contact, o2: Contact) => -Contact.compareVisitCount(o1, o2);

  countryDialCode: string = '';

  fullTextFilterQuery: string = '';

  fullTextFilteredContacts: Contact[] | null = null;
}

function newContacts(state: GuestbookState) {
  state.duplicateContactCountsByEmail = new Map<string, number>();
  state.duplicateContactCountsByPhone = new Map<string, number>();
  addToCountMap(state.duplicateContactCountsByEmail, state.contacts, (c) => c.normalizedEmail());
  addToCountMap(state.duplicateContactCountsByPhone, state.contacts, (c) => c.normalizedPhone(state.countryDialCode));
}

function mergeContacts(state: GuestbookState, contacts: Contact[]) {
  const deletedContacts = deleteModelEntities(state.contacts, contacts);
  removeFromCountMap(state.duplicateContactCountsByEmail, deletedContacts, (c) => c.normalizedEmail());
  removeFromCountMap(state.duplicateContactCountsByPhone, deletedContacts, (c) => c.normalizedPhone(state.countryDialCode));

  const addedContacts = contacts.filter((c) => c.isDeleted !== true);
  state.contacts.push(...addedContacts);
  addToCountMap(state.duplicateContactCountsByEmail, addedContacts, (c) => c.normalizedEmail());
  addToCountMap(state.duplicateContactCountsByPhone, addedContacts, (c) => c.normalizedPhone(state.countryDialCode));
}

function newFilteredContacts(state: GuestbookState) {
  const filteredContacts = state.contacts.filter((c) => c.isDeleted !== true && state.filter?.check(c) === true);
  filteredContacts.sort(state.contactCompareFnc);
  state.filteredContacts = filteredContacts;
  state.filteredContactsById = toMapById(filteredContacts);
  searchContactAdd(filteredContacts);

  if (state.fullTextFilterQuery.length < 3) {
    state.fullTextFilteredContacts = null;
  } else {
    const contacts = searchForContacts(state.fullTextFilterQuery, state.filteredContactsById);
    state.fullTextFilteredContacts = contacts.sort(state.contactCompareFnc);
  }
}

function mergeFilteredContacts(state: GuestbookState, contacts: Contact[]) {
  const deletedContacts = deleteModelEntities(state.filteredContacts, contacts);
  deletedContacts.forEach((c) => state.filteredContactsById.delete(c.id));
  searchContactRemove(deletedContacts);

  const filteredContacts = contacts.filter((c) => c.isDeleted !== true && state.filter?.check(c) === true);
  filteredContacts.sort(state.contactCompareFnc);
  const addedContacts = mergeSortedModelEntities(state.filteredContacts, filteredContacts, state.contactCompareFnc);
  addedContacts.forEach((c) => state.filteredContactsById.set(c.id, c));
  searchContactAdd(filteredContacts);

  state.fullTextFilterQuery = '';
  state.fullTextFilteredContacts = null;
}

const mutations = <MutationTree<IGuestbookState>>{
  RESET(state: GuestbookState) {
    Object.assign(state, new GuestbookState());
    searchContactReset();
  },
  RESET_GUESTBOOK(state: GuestbookState) {
    Object.assign(state, new GuestbookState());
    searchContactReset();
  },
  // CLEAR_GUESTBOOK(state: GuestbookState) {
  //   state.contacts = [];
  //   state.filteredContacts = [];
  //   state.duplicateContactCountsByEmail = new Map<string, number>();
  //   state.duplicateContactCountsByPhone = new Map<string, number>();
  //   state.contactFilters = [];
  // },
  UPDATE_GUESTBOOK_CONTACT(state: GuestbookState, p: { contact: Contact }) {
    mergeContacts(state, [p.contact]);
    mergeFilteredContacts(state, [p.contact]);

    console.log('UPDATE_GUESTBOOK_CONTACT: added contact=', p.contact);
  },
  UPDATE_GUESTBOOK_CONTACTS(state: GuestbookState, p: { contacts: Contact[] }) {
    mergeContacts(state, p.contacts);
    mergeFilteredContacts(state, p.contacts);

    console.log('UPDATE_GUESTBOOK_CONTACTS: added contacts=', p.contacts);
  },
  UPDATE_GUESTBOOK_CONTACT_FILTER(state: GuestbookState, p: { contactFilter: ContactFilter }) {
    deleteModelEntities(state.contactFilters, [p.contactFilter]);
    mergeSortedModelEntities(state.contactFilters, [p.contactFilter], ContactFilter.compare);

    if (state.contactFilter.id === p.contactFilter.id) {
      state.contactFilter = ContactFilter.DefaultFilter;
      state.filter = FilterConvert.fromJson(ContactFilter.DefaultFilter.data!);
      state.filterError = null;

      newFilteredContacts(state);
    }
  },
  UPDATE_GUESTBOOK(state: GuestbookState, p: {
    contacts?: Contact[], contactFilters?: ContactFilter[],
    isLoaded?: boolean, isFullyLoaded?: boolean,
    countryDialCode?: string,
  }) {
    if (p.countryDialCode) state.countryDialCode = p.countryDialCode;

    if (p.contacts) {
      state.contacts.push(...p.contacts);
      state.filteredContacts.push(...p.contacts);

      if (p.contacts.length === 0) {
        newContacts(state);
        newFilteredContacts(state);
      }
    }

    if (p.contactFilters) mergeSortedModelEntities(state.contactFilters, p.contactFilters.sort(ContactFilter.compare), ContactFilter.compare);

    if (p.isLoaded) state.isLoaded = p.isLoaded;
    if (p.isFullyLoaded) state.isFullyLoaded = p.isFullyLoaded;

    console.log('Guestbook contacts: ', `${state.contacts.length} contacts loaded`);
    console.log('Guestbook contact filters: ', state.contactFilters);
  },
  APPLY_GUESTBOOK_FILTER(state: GuestbookState, p: { contactFilter: ContactFilter }) {
    state.contactFilter = p.contactFilter;

    state.filter = null;
    state.filterError = null;

    try {
      if (p.contactFilter.data) state.filter = FilterConvert.fromJson(p.contactFilter.data);
      else state.filterError = new Error('no data');
    } catch (e) {
      state.filterError = e instanceof Error ? e : new Error('error');
    }

    newFilteredContacts(state);
  },
  UPDATE_GUESTBOOK_PAGE(state: GuestbookState, p: { pageSize?: number, pageStart?: number }) {
    if (p.pageSize) state.visiblePageSize = p.pageSize;
    if (p.pageStart !== undefined) state.visiblePageStart = p.pageStart;
  },
  UPDATE_GUESTBOOK_SORT(state: GuestbookState, p: { sortType?: ContactSortType, sortDesc?: boolean }) {
    if (p.sortType !== undefined) state.contactSortType = p.sortType;
    if (p.sortDesc !== undefined) state.contactSortDesc = p.sortDesc;

    state.contactCompareFnc = state.contactSortDesc ? Contact.compareFncDesc(state.contactSortType) : Contact.compareFnc(state.contactSortType);
    state.filteredContacts.sort(state.contactCompareFnc);

    if (state.fullTextFilteredContacts) state.fullTextFilteredContacts = state.fullTextFilteredContacts.sort(state.contactCompareFnc);
  },
  UPDATE_GUESTBOOK_FULLTEXT_FILTER(state: GuestbookState, p: { query?: string }) {
    if (p.query === undefined || state.fullTextFilterQuery === p.query) return;
    state.fullTextFilterQuery = p.query;

    if (state.fullTextFilterQuery.length < 3) {
      state.fullTextFilteredContacts = null;
      return;
    }

    const contacts = searchForContacts(state.fullTextFilterQuery, state.filteredContactsById);
    state.fullTextFilteredContacts = contacts.sort(state.contactCompareFnc);
  },
};

const actions = <ActionTree<IGuestbookState, IRootState>>{
  async resetGuestbook({ commit, rootGetters }) {
    commit('RESET_GUESTBOOK');
  },
  async loadGuestbook({ rootState, commit, rootGetters }, p: { errorFnc: (e: any) => void }): Promise<boolean> {
    if (!rootGetters.isLoaded) return false;

    let someContactsLoaded = false;
    let someContactFiltersLoaded = false;
    let allContactsLoaded = false;
    let allContactFiltersLoaded = false;

    commit('RESET_GUESTBOOK');

    const countryCode = rootState.settings.account.countryCode ?? '';
    const countryDialCode = dialCodeByCountry.get(countryCode) ?? '';
    commit('UPDATE_GUESTBOOK', { countryDialCode });

    const commitData = (cp: { contacts?: Contact[], contactFilters?: ContactFilter[]}) => {
      const isLoaded = (someContactsLoaded && allContactFiltersLoaded) || undefined;
      const isFullyLoaded = (allContactsLoaded && allContactFiltersLoaded) || undefined;
      commit('UPDATE_GUESTBOOK', { ...cp, isLoaded, isFullyLoaded });
    };

    await Promise.all([
      grpcClient.listContacts(
        (data: Contact[]) => {
          someContactsLoaded = true;
          if (data.length === 0) allContactsLoaded = true;
          commitData({ contacts: data });
        },
        (error: Error) => { p.errorFnc(error); },
        rootGetters as ContactMapping,
      ),
      grpcClient.listContactFilters(
        (data: ContactFilter[]) => {
          someContactFiltersLoaded = true;
          if (data.length === 0) allContactFiltersLoaded = true;
          commitData({ contactFilters: data });
        },
        (error: Error) => { p.errorFnc(error); },
      ),
    ]);

    return true;
  }, // ContactFilter
  async crudContactFilter(
    { commit, rootState, rootGetters },
    p: { contactFilter: ContactFilter, errorFnc: (e: any) => void, operation: string },
  ): Promise<ContactFilter|null> {
    if ((grpcClient as any)[p.operation] === undefined) throw new Error(`grpc operation '${p.operation}' error: not implemented`);
    if (!rootGetters.isLoaded) throw new Error(`grpc operation '${p.operation}' error: not loaded`);

    const contactFilter = await (grpcClient as any)[p.operation](
      p.contactFilter,
    );
    commit('UPDATE_GUESTBOOK_CONTACT_FILTER', { contactFilter });

    return contactFilter;
  },
  async guestbookCreateContactFilter({ dispatch }, p: any) { return dispatch('crudContactFilter', { ...p, operation: 'createContactFilter' }); },
  async guestbookGetContactFilter({ dispatch }, p: any) { return dispatch('crudContactFilter', { ...p, operation: 'getContactFilter' }); },
  async guestbookUpdateContactFilter({ dispatch }, p: any) { return dispatch('crudContactFilter', { ...p, operation: 'updateContactFilter' }); },
  async guestbookDeleteContactFilter({ dispatch }, p: any) { return dispatch('crudContactFilter', { ...p, operation: 'deleteContactFilter' }); },

  // Contact
  async crudContact(
    {
      state, rootState, commit, getters, rootGetters,
    },
    p: { contact: Contact, errorFnc: (e: any) => void, operation: string },
  ): Promise<Contact|null> {
    if ((grpcClient as any)[p.operation] === undefined) throw new Error(`grpc operation '${p.operation}' error: not implemented`);
    if (!state.isFullyLoaded) throw new Error(`grpc operation '${p.operation}' error: not fully loaded`);

    const employee = getters.guestbookLastEmployee ?? undefined as Employee | undefined;
    const contact = await (grpcClient as any)[p.operation](
      p.contact,
      employee,
      rootGetters as ContactMapping,
    );
    commit('UPDATE_GUESTBOOK_CONTACT', { contact });

    return contact;
  },
  async guestbookCreateContact({ dispatch }, p: any) { return dispatch('crudContact', { ...p, operation: 'createContact' }); },
  async guestbookUpdateContact({ dispatch }, p: any) { return dispatch('crudContact', { ...p, operation: 'updateContact' }); },

  // ContactManager
  async guestbookDeleteContacts({
    rootState, commit, dispatch, getters, rootGetters,
  }, p: { contacts: Contact[], errorFnc: OnErrorFnc }) {
    const employee = getters.guestbookLastEmployee ?? undefined as Employee | undefined;
    const returnedContacts: Contact[] = [];

    await grpcClient.deleteContacts(
      p.contacts.map((c) => c.id),
      employee,
      (contacts: Contact[]) => { returnedContacts.push(...contacts); commit('UPDATE_GUESTBOOK_CONTACTS', { contacts }); },
      (error: ApiError) => { p.errorFnc(error); },
    );

    return returnedContacts;
  },

  async guestbookMergeContacts({
    rootState, commit, dispatch, getters, rootGetters,
  }, p: { contact: Contact, contacts: Contact[], errorFnc: OnErrorFnc }) {
    const employee = getters.guestbookLastEmployee ?? undefined as Employee | undefined;
    const returnedContacts: Contact[] = [];

    await grpcClient.mergeContacts(
      p.contact,
      employee,
      p.contacts.map((c) => c.id),
      (contacts: Contact[]) => { returnedContacts.push(...contacts); commit('UPDATE_GUESTBOOK_CONTACTS', { contacts }); },
      (error: ApiError) => { p.errorFnc(error); },
      rootGetters as ContactMapping,
    );

    return returnedContacts;
  },

  async guestbookSelectedContacts({ rootState, commit, rootGetters }, p: { contacts: Contact[], errorFnc: OnErrorFnc }) {
    const returnedContacts: Contact[] = [];
    await grpcClient.selectedContacts(
      p.contacts.map((c) => c.id),
      (contacts: Contact[]) => { returnedContacts.push(...contacts); /* commit('UPDATE_GUESTBOOK_CONTACTS', { contacts }); */ },
      (error: ApiError) => { p.errorFnc(error); },
      rootGetters as ContactMapping,
    );
    return returnedContacts;
  },

  async guestbookSearchContacts({ rootState, commit, rootGetters }, p: { filter: string, errorFnc: OnErrorFnc }) {
    const returnedContacts: Contact[] = [];
    await grpcClient.searchContacts(
      p.filter,
      (contacts: Contact[]) => { returnedContacts.push(...contacts); commit('UPDATE_GUESTBOOK_CONTACTS', { contacts }); },
      (error: ApiError) => { p.errorFnc(error); },
      rootGetters as ContactMapping,
    );
    return returnedContacts;
  },

  // Other
  async guestbookApplyContactFilter({ rootState, commit }, p: { contactFilter: ContactFilter }) {
    commit('APPLY_GUESTBOOK_FILTER', { contactFilter: p.contactFilter });
  },
  async guestbookPreviewContactFilter({ state }, p: { contactFilter: ContactFilter }) {
    if (!p.contactFilter.data) return 0;
    const filter = FilterConvert.fromJson(p.contactFilter.data);
    return filter.count(state.contacts);
  },
  async guestbookContactReservations({ state, rootGetters }, p: { contact: Contact }) {
    const query = `contactId:${p.contact.id}`;
    const update = await httpClient.search(query);
    const reservations = (update.reservations ?? []).map(
      (o) => new Reservation(o, rootGetters as ReservationMapping),
    );
    return reservations;
  },
  async updateGuestbookPage({ commit }, p: { pageSize?: number, pageStart?: number }) {
    commit('UPDATE_GUESTBOOK_PAGE', p);
  },
  async updateGuestbookSort({ commit }, p: { sortType?: ContactSortType, sortDesc?: boolean }) {
    commit('UPDATE_GUESTBOOK_SORT', p);
  },
  async updateGuestbookFullTextFilter({ commit }, p: { query?: string }) {
    commit('UPDATE_GUESTBOOK_FULLTEXT_FILTER', p);
  },
};

const getters = <GetterTree<IGuestbookState, IRootState>>{
  isGuestbookLoaded(state: GuestbookState, localGetters: any, rootState: any, rootGetters: any): boolean {
    return state.isLoaded && rootGetters.isLoaded;
  },
  guestbookFilteredContacts(state: GuestbookState, localGetters: any, rootState: any, rootGetters: any): Contact[] {
    return state.filteredContacts;
  },
  guestbookLastEmployee(state: GuestbookState, localGetters: any, rootState: any, rootGetters: any): Employee | null {
    const ae = rootGetters.reservationLastEmployee;
    if (!ae) return null;
    return Employee.fromApiModel(ae);
  },
  guestbookDuplicatedMails(state: GuestbookState): string[] {
    const mails = [] as string[];
    state.duplicateContactCountsByEmail.forEach((count, key) => { if (count > 1) mails.push(key); });
    return mails;
  },
  guestbookDuplicatedPhones(state: GuestbookState): string[] {
    const phones = [] as string[];
    state.duplicateContactCountsByPhone.forEach((count, key) => { if (count > 1) phones.push(key); });
    return phones;
  },
  guestbookVisibleCount(state: GuestbookState): number {
    const contacts = state.fullTextFilteredContacts ?? state.filteredContacts;
    return contacts.length;
  },
  guestbookVisibleContacts(state: GuestbookState): Contact[] {
    const contacts = state.fullTextFilteredContacts ?? state.filteredContacts;
    return contacts.slice(state.visiblePageStart, state.visiblePageStart + state.visiblePageSize);
  },
};

const GuestbookStore = {
  namespaced: false,
  state: new GuestbookState(),
  mutations,
  actions,
  getters,
};

export default GuestbookStore;
