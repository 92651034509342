import Vue from 'vue';
import Router from 'vue-router';
import GuestsView from '@/components/views/Guests.vue';
import TablesView from '@/components/views/Tables.vue';
import FloorView from '@/components/views/Floor.vue';
import LoginView from '@/components/views/Login.vue';
import store from '@/store/store';
import AccountView from '@/components/views/Account.vue';
import AdvancedSettingsView from '@/components/views/AdvancedSettings.vue';
import UsersView from '@/components/views/Users.vue';
import UserView from '@/components/views/User.vue';
import ContactsView from '@/components/views/Contacts.vue';
import TagNotesView from '@/components/views/TagNotes.vue';
import EmployeesView from '@/components/views/Employees.vue';
import LabelsView from '@/components/views/Labels.vue';
import OpeningHours from '@/components/views/OpeningHours.vue';
import SimpleOpeningHours from '@/components/views/SimpleOpeningHours.vue';
import Sections from '@/components/views/Sections.vue';
import Section from '@/components/views/Section.vue';
import OnlineSettings from '@/components/views/OnlineSettings.vue';
import ServiceManager from '@/components/views/ServiceManager.vue';
import ServiceManagerEditor from '@/components/views/settings/servicemanager/ServiceManagerEditor.vue';
import Sms from '@/components/views/Sms.vue';
import Settings from '@/components/views/Settings.vue';
import SpecialHours from '@/components/views/SpecialHours.vue';
import MessageCenter from '@/components/views/MessageCenter.vue';
import Dashboard from '@/components/views/Dashboard.vue';
import ReportReservations from '@/components/views/ReportReservations.vue';
import ReportServices from '@/components/views/ReportServices.vue';
import ReportWeekdays from '@/components/views/ReportWeekdays.vue';
import ReportFeedback from '@/components/views/ReportFeedback.vue';
import ReportMessages from '@/components/views/ReportMessages.vue';
import Search from '@/components/views/Search.vue';
import MessageCenterRuleEditor from '@/components/views/MessageCenterRuleEditor.vue';
import UnreadReservations from '@/components/views/UnreadReservations.vue';
import PwaInfo from '@/components/views/PwaInfo.vue';
import Feedback from '@/components/views/Feedback.vue';
import SettingsFeedback from '@/components/views/settings/Feedback.vue';
import NewsletterCampaignsView from '@/components/views/newsletter/NewsletterCampaigns.vue';
import NewsletterComposeView from '@/components/views/newsletter/NewsletterCampaignCompose.vue';
import NewsletterTemplateLibraryView from '@/components/views/newsletter/NewsletterTemplateLibrary.vue';
import NewsletterSettingsView from '@/components/views/newsletter/NewsletterSettings.vue';

import Apps from '@/components/views/Apps.vue';
import i18n from '@/plugins/i18n';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: GuestsView,
      meta: { hasDatePicker: true, fab: 'reservation' },
    },
    {
      path: '/guests',
      name: 'guests',
      component: GuestsView,
      meta: { hasDatePicker: true, fab: 'reservation' },
    },
    {
      path: '/tables',
      name: 'tables',
      component: TablesView,
      meta: { hasDatePicker: true, fab: 'reservation' },
    },
    {
      path: '/floor',
      name: 'floorplan',
      component: FloorView,
      meta: { hasDatePicker: true, fab: 'reservation' },
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView,
      meta: { public: true },
    },
    {
      path: '/promo',
      name: 'promo',
      component: LoginView,
      meta: { public: true },
    },
    {
      path: '/promo/:code',
      name: 'promo',
      component: LoginView,
      meta: { public: true },
    },
    {
      path: '/signup',
      name: 'signup',
      component: LoginView,
      meta: { public: true },
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: ContactsView,
      meta: {
        title: i18n.t('title.contacts'),
        titleKey: 'title.contacts',
      },
    },
    {
      path: '/newsletter',
      name: 'newsletter',
      component: NewsletterCampaignsView,
      meta: {
        title: i18n.t('title.newsletter_campaigns'),
        titleKey: 'title.newsletter_campaigns',
      },
    },
    {
      path: '/newsletter/campaigns',
      name: 'newsletter',
      component: NewsletterCampaignsView,
      meta: {
        title: i18n.t('title.newsletter_campaigns'),
        titleKey: 'title.newsletter_campaigns',
      },
    },
    {
      path: '/newsletter/library',
      name: 'newsletter_library',
      component: NewsletterTemplateLibraryView,
      meta: {
        title: i18n.t('title.newsletter_library'),
        titleKey: 'title.newsletter_library',
      },
    },
    {
      path: '/newsletter/:id',
      name: 'newsletter_compose',
      component: NewsletterComposeView,
      meta: {
        title: i18n.t('title.newsletter_campaign_compose'),
        titleKey: 'title.newsletter_campaign_compose',
      },
    },
    {
      path: '/newsletter/settings',
      name: 'newsletter_settings',
      component: NewsletterSettingsView,
      meta: {
        title: i18n.t('title.settings'),
        titleKey: 'title.settings',
      },
    },
    // Settings Start
    {
      path: '/settings/general',
      name: 'general',
      component: AccountView,
      meta: {
        title: i18n.t('title.general'),
        titleKey: 'title.general',
        fab: 'help',
        helpUrl: 'account',
      },
    },
    {
      path: '/settings/billing',
      name: 'billing',
      component: () => import('@/components/views/Billing.vue'),
      meta: {
        title: i18n.t('title.billing'),
        titleKey: 'title.billing',
        fab: 'help',
        helpUrl: 'billing',
      },
    },
    {
      path: '/settings/advanced',
      name: 'advanced_settings',
      component: AdvancedSettingsView,
      meta: {
        title: i18n.t('title.advanced_settings'),
        titleKey: 'title.advanced_settings',
        fab: 'help',
        helpUrl: 'advanced_settings?id=h-advanced-settings',
      },
    },
    {
      path: '/settings/users',
      name: 'users',
      component: UsersView,
      meta: {
        title: i18n.t('title.users'),
        titleKey: 'title.users',
        fab: 'help',
        helpUrl: 'users',
      },
    },
    {
      path: '/settings/users/:id',
      name: 'user',
      component: UserView,
      meta: {
        title: i18n.t('title.edit_user'),
        titleKey: 'title.edit_user',
        fab: 'help',
        helpUrl: 'users',
      },
    },
    {
      path: '/settings/employees',
      name: 'employees',
      component: EmployeesView,
      meta: {
        title: i18n.t('title.manage_employee_names'),
        titleKey: 'title.manage_employee_names',
        fab: 'help',
        helpUrl: 'advanced_settings?id=h-employees',
      },
    },
    {
      path: '/settings/labels',
      name: 'labels',
      component: LabelsView,
      meta: {
        title: i18n.t('title.manage_reservation_labels'),
        titleKey: 'title.manage_reservation_labels',
        fab: 'help',
        helpUrl: 'advanced_settings?id=h-reservation-labels',
      },
    },
    {
      path: '/settings/tagnotes',
      name: 'tag_notes',
      component: TagNotesView,
      meta: {
        title: i18n.t('title.manage_tags'),
        titleKey: 'title.manage_tags',
        fab: 'help',
        helpUrl: 'advanced_settings?id=h-tags-and-notes',
      },
    },
    {
      path: '/settings/hours',
      name: 'opening_hours',
      component: OpeningHours,
      meta: {
        title: i18n.t('title.set_opening_hours'),
        titleKey: 'title.set_opening_hours',
        fab: 'help',
        helpUrl: 'opening_hours',
      },
    },
    {
      path: '/settings/simple-hours',
      name: 'opening_hours',
      component: SimpleOpeningHours,
      meta: {
        title: i18n.t('title.opening_hours'),
        titleKey: 'title.opening_hours',
      },
    },
    {
      path: '/settings/sections',
      name: 'sections',
      component: Sections,
      meta: {
        title: i18n.t('title.sections'),
        titleKey: 'title.sections',
        fab: 'help',
        helpUrl: 'sections',
      },
    },
    {
      path: '/settings/sections/:id',
      name: 'section',
      component: Section,
      meta: {
        title: i18n.t('title.edit_section'),
        titleKey: 'title.edit_section',
        fab: 'help',
        helpUrl: 'sections',
      },
    },
    {
      path: '/settings/online',
      name: 'online',
      component: OnlineSettings,
      meta: {
        title: i18n.t('title.online_settings'),
        titleKey: 'title.online_settings',
        fab: 'help',
        helpUrl: 'online_settings',
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: {
        title: i18n.t('title.settings'),
        titleKey: 'title.settings',
      },
    },
    {
      path: '/settings/special-hours',
      name: 'special-hours',
      component: SpecialHours,
      meta: {
        title: i18n.t('title.set_special_hours'),
        titleKey: 'title.set_special_hours',
        fab: 'help',
        helpUrl: 'opening_hours?id=h-special-opening-hours',
      },
    },
    {
      path: '/settings/feedback',
      name: 'feedback',
      component: SettingsFeedback,
      meta: {
        title: i18n.t('title.feedback'),
        titleKey: 'title.feedback',
        fab: 'help',
        helpUrl: 'feedback',
      },
    },
    {
      path: '/settings/sms',
      name: 'sms',
      component: Sms,
      meta: {
        title: i18n.t('title.sms'),
        titleKey: 'title.sms',
        fab: 'help',
        helpUrl: 'sms',
      },
    },
    {
      path: '/settings/message-center',
      name: 'message-center',
      component: MessageCenter,
      meta: {
        title: i18n.t('title.message_center'),
        titleKey: 'title.message_center',
        fab: 'help',
        helpUrl: 'messages',
      },
    },
    {
      path: '/settings/message-center/:id',
      name: 'message-center-rule',
      component: MessageCenterRuleEditor,
      meta: {
        title: i18n.t('title.messages'),
        titleKey: 'title.messages',
        fab: 'help',
        helpUrl: 'messages',
      },
    },
    {
      path: '/settings/service-manager',
      name: 'service-manager',
      component: ServiceManager,
      meta: {
        title: i18n.t('title.service_manager'),
        titleKey: 'title.service_manager',
        fab: 'help',
        helpUrl: 'services',
      },
    },
    {
      path: '/settings/service-manager/:id',
      name: 'service-manager-editor',
      component: ServiceManagerEditor,
      meta: {
        title: i18n.t('title.service_manager'),
        titleKey: 'title.service_manager',
        fab: 'help',
        helpUrl: 'services',
      },
    },
    {
      path: '/apps/manage',
      name: 'apps',
      component: Apps,
      meta: {
        title: i18n.t('title.manage_apps'),
        titleKey: 'title.manage_apps',
        fab: 'help',
        helpUrl: 'apps',
      },
    },

    // end settings
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        title: i18n.t('title.dashboard'), hasDatePicker: true, titleKey: 'title.dashboard', fab: 'reservation',
      },
    },
    {
      path: '/search',
      name: 'search',
      component: Search,
      meta: { title: i18n.t('title.search'), titleKey: 'title.search' },
    },
    {
      path: '/unread-reservations',
      name: 'unread-reservations',
      component: UnreadReservations,
      meta: { title: i18n.t('title.unread_reservations'), titleKey: 'title.unread_reservations' },
    },
    {
      path: '/report/reservations',
      name: 'reports-reservations',
      component: ReportReservations,
      meta: { title: i18n.t('title.report_reservations'), titleKey: 'title.report_reservations' },
    },
    {
      path: '/report/services',
      name: 'reports-services',
      component: ReportServices,
      meta: { title: i18n.t('title.report_services'), titleKey: 'title.report_services' },
    },
    {
      path: '/report/weekdays',
      name: 'reports-weekdays',
      component: ReportWeekdays,
      meta: { title: i18n.t('title.report_weekdays'), titleKey: 'title.report_weekdays' },
    },
    {
      path: '/report/feedback',
      name: 'reports-feedback',
      component: ReportFeedback,
      meta: { title: i18n.t('title.feedback'), titleKey: 'title.feedback' },
    },
    {
      path: '/report/messages',
      name: 'reports-messages',
      component: ReportMessages,
      meta: { title: i18n.t('title.report_messages'), titleKey: 'title.report_messages' },
    },
    {
      path: '/feedback',
      name: 'feedback',
      component: Feedback,
      meta: { title: i18n.t('title.feedback'), titleKey: 'title.feedback' },
    },
    {
      path: '/pwa-info',
      name: 'pwa-info',
      component: PwaInfo,
      meta: { title: 'PWA Info', titleKey: 'title.pwa_info' },
    },
  ],
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page

  const isSingle = to.meta?.single === true;
  const isPublic = to.meta?.public === true;
  const isLoggedIn = store.getters.isLoggedIn as boolean;
  const isRegistered = store.getters.isRegistered as boolean;
  const isLoaded = store.getters.isLoaded as boolean;

  if (isPublic && isSingle) {
    return next();
  }

  // not logged in -> login screen
  if (!isPublic && !isLoggedIn && to.path !== '/login') {
    return next('/login');
  }

  // logged in but unregistered -> register on login screen
  if (!isPublic && isLoaded && !isRegistered && to.path !== '/login') {
    return next('/login');
  }

  if (isSingle) {
    return next();
  }

  // logged in -> no login screen
  if (isLoggedIn && isRegistered && isPublic) {
    return next('/');
  }

  // payment result
  // eslint-disable-next-line camelcase
  const { payment_intent, setup_intent, invoice } = to.query;
  // eslint-disable-next-line camelcase
  if ((payment_intent || setup_intent || invoice) && to.name !== 'billing') {
    next({ name: 'billing', query: to.query });
  }

  return next();
});

export default router;
