/* eslint-disable max-len */
/* eslint-disable vue/max-len */

import * as pbm from '@/grpc-pb/marketing_campaign_settings_pb';
import * as pbs from '@/grpc-pb/marketing_campaign_manager_service_pb';

import ContactFilter from './contact-filter';

export interface MarketingCampaignMapping {
  marketingCampaignManagerContactFilters: ContactFilter[];
}

export default class MarketingCampaignSettings {
  allowedMessages: number = 0;

  createdMessages: number = 0;

  dateQuotaReset: Date = new Date();

  static fromGrpcModel(o?: pbm.MarketingCampaignSettings, m = undefined): MarketingCampaignSettings {
    const mcs = new MarketingCampaignSettings();

    mcs.allowedMessages = o?.getAllowedMessages() ?? 0;
    mcs.createdMessages = o?.getCreatedMessages() ?? 0;
    mcs.dateQuotaReset = o ? new Date(o.getDtQuotaReset() * 1000) : new Date();

    return mcs;
  }

  static fromGrpcResponse(r: pbs.MarketingCampaignSettingsResponse): MarketingCampaignSettings {
    const e = r.getSettings();
    return MarketingCampaignSettings.fromGrpcModel(e);
  }
}
