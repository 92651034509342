/**
 * @fileoverview gRPC-Web generated client stub for grpc.api.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.1
// source: html_template_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var html_template_pb = require('./html_template_pb.js')
const proto = {};
proto.grpc = {};
proto.grpc.api = {};
proto.grpc.api.v1 = require('./html_template_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.grpc.api.v1.HtmlTemplateServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.grpc.api.v1.HtmlTemplateServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.HtmlTemplateIdRequest,
 *   !proto.grpc.api.v1.HtmlTemplateResponse>}
 */
const methodDescriptor_HtmlTemplateService_get = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.HtmlTemplateService/get',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.HtmlTemplateIdRequest,
  proto.grpc.api.v1.HtmlTemplateResponse,
  /**
   * @param {!proto.grpc.api.v1.HtmlTemplateIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.HtmlTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.HtmlTemplateIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.HtmlTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.HtmlTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.HtmlTemplateServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.HtmlTemplateService/get',
      request,
      metadata || {},
      methodDescriptor_HtmlTemplateService_get,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.HtmlTemplateIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.HtmlTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.HtmlTemplateServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.HtmlTemplateService/get',
      request,
      metadata || {},
      methodDescriptor_HtmlTemplateService_get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.HtmlTemplateRequest,
 *   !proto.grpc.api.v1.HtmlTemplateResponse>}
 */
const methodDescriptor_HtmlTemplateService_create = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.HtmlTemplateService/create',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.HtmlTemplateRequest,
  proto.grpc.api.v1.HtmlTemplateResponse,
  /**
   * @param {!proto.grpc.api.v1.HtmlTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.HtmlTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.HtmlTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.HtmlTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.HtmlTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.HtmlTemplateServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.HtmlTemplateService/create',
      request,
      metadata || {},
      methodDescriptor_HtmlTemplateService_create,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.HtmlTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.HtmlTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.HtmlTemplateServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.HtmlTemplateService/create',
      request,
      metadata || {},
      methodDescriptor_HtmlTemplateService_create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.HtmlTemplateRequest,
 *   !proto.grpc.api.v1.HtmlTemplateResponse>}
 */
const methodDescriptor_HtmlTemplateService_update = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.HtmlTemplateService/update',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.HtmlTemplateRequest,
  proto.grpc.api.v1.HtmlTemplateResponse,
  /**
   * @param {!proto.grpc.api.v1.HtmlTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.HtmlTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.HtmlTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.HtmlTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.HtmlTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.HtmlTemplateServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.HtmlTemplateService/update',
      request,
      metadata || {},
      methodDescriptor_HtmlTemplateService_update,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.HtmlTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.HtmlTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.HtmlTemplateServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.HtmlTemplateService/update',
      request,
      metadata || {},
      methodDescriptor_HtmlTemplateService_update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.HtmlTemplateIdRequest,
 *   !proto.grpc.api.v1.HtmlTemplateResponse>}
 */
const methodDescriptor_HtmlTemplateService_delete = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.HtmlTemplateService/delete',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.HtmlTemplateIdRequest,
  proto.grpc.api.v1.HtmlTemplateResponse,
  /**
   * @param {!proto.grpc.api.v1.HtmlTemplateIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.HtmlTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.HtmlTemplateIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.HtmlTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.HtmlTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.HtmlTemplateServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.HtmlTemplateService/delete',
      request,
      metadata || {},
      methodDescriptor_HtmlTemplateService_delete,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.HtmlTemplateIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.HtmlTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.HtmlTemplateServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.HtmlTemplateService/delete',
      request,
      metadata || {},
      methodDescriptor_HtmlTemplateService_delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.HtmlTemplateListRequest,
 *   !proto.grpc.api.v1.HtmlTemplateListResponse>}
 */
const methodDescriptor_HtmlTemplateService_list = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.HtmlTemplateService/list',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.grpc.api.v1.HtmlTemplateListRequest,
  proto.grpc.api.v1.HtmlTemplateListResponse,
  /**
   * @param {!proto.grpc.api.v1.HtmlTemplateListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.HtmlTemplateListResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.HtmlTemplateListRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.HtmlTemplateListResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.HtmlTemplateServiceClient.prototype.list =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.HtmlTemplateService/list',
      request,
      metadata || {},
      methodDescriptor_HtmlTemplateService_list);
};


/**
 * @param {!proto.grpc.api.v1.HtmlTemplateListRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.HtmlTemplateListResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.HtmlTemplateServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.HtmlTemplateService/list',
      request,
      metadata || {},
      methodDescriptor_HtmlTemplateService_list);
};


module.exports = proto.grpc.api.v1;

