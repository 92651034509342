import { render, staticRenderFns } from "./NewsletterFunnelChart.vue?vue&type=template&id=68ea8c67&scoped=true&"
import script from "./NewsletterFunnelChart.vue?vue&type=script&setup=true&lang=ts&"
export * from "./NewsletterFunnelChart.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./NewsletterFunnelChart.vue?vue&type=style&index=0&id=68ea8c67&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68ea8c67",
  null
  
)

export default component.exports