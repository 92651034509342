/* eslint-disable no-param-reassign */
import { GstplnAppCategory } from '@/components/apps/app';
import Vue from 'vue';

import CampaignFilterApp from '@/components/apps/campaign-filter/campaign-filter';
import ExportApp from '@/components/apps/export/export';
import StripeApp from '@/components/apps/stripe/stripe';
import StripeConnectApp from '@/components/apps/stripe-connect/stripe-connect';
import DyflexisApp from '@/components/apps/dyflexis/dyflexis';
import BooqPlannerApp from '@/components/apps/booq-planner/booq-planner';
import SimpleCampaignApp from '@/components/apps/simpleCampaignApp/simpleCampaignApp';
import GiftcardsApp from '@/components/apps/giftcards/giftcards';
import AnnoncerApp from '@/components/apps/annoncer/annoncer';
import BookDinnersApp from '@/components/apps/bookdinners/bookdinners';
import { initializeApps } from './apps';

export default function createApps(vue: Vue) {
  const apps = [
    new CampaignFilterApp(vue),
    new ExportApp(vue),

    new SimpleCampaignApp(vue, 'app.actievandedag', {
      categories: [GstplnAppCategory.BookingPartners],
      allowedCountries: ['NL'],
    }),

    new SimpleCampaignApp(vue, 'app.diningcity', {
      categories: [GstplnAppCategory.BookingPartners],
      allowedCountries: ['NL'],
    }),

    new SimpleCampaignApp(vue, 'app.heerlijk', {
      categories: [GstplnAppCategory.BookingPartners],
      allowedCountries: ['NL'],
    }),

    new SimpleCampaignApp(vue, 'app.socialdeal', {
      categories: [GstplnAppCategory.BookingPartners],
      allowedCountries: ['NL'],
    }),

    new SimpleCampaignApp(vue, 'app.hightea', {
      categories: [GstplnAppCategory.BookingPartners],
      allowedCountries: ['NL'],
    }),
    new SimpleCampaignApp(vue, 'app.gault-millau', {
      categories: [GstplnAppCategory.BookingPartners],
      allowedCountries: ['NL', 'BE', 'FR'],
    }),
    new SimpleCampaignApp(vue, 'app.unbookables', {
      categories: [GstplnAppCategory.BookingPartners],
      allowedCountries: ['NL'],
    }),
    new AnnoncerApp(vue),
    new BookDinnersApp(vue),
    new SimpleCampaignApp(vue, 'app.breeze-social', { categories: [GstplnAppCategory.BookingPartners] }),
    new SimpleCampaignApp(vue, 'app.nappkin', { categories: [GstplnAppCategory.BookingPartners] }),
    new SimpleCampaignApp(vue, 'app.my-lucky-table', { categories: [GstplnAppCategory.BookingPartners] }),

    new SimpleCampaignApp(vue, 'app.shiftbase', { categories: [GstplnAppCategory.Integrations] }),
    new SimpleCampaignApp(vue, 'app.workfeed', { categories: [GstplnAppCategory.Integrations] }),
    new SimpleCampaignApp(vue, 'app.mice', { categories: [GstplnAppCategory.Integrations] }),

    new StripeApp(vue),
    new StripeConnectApp(vue),
    new DyflexisApp(vue),
    new BooqPlannerApp(vue),
    new GiftcardsApp(vue),
  ];

  initializeApps(apps);
}
