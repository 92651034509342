/* eslint-disable vue/max-len */
/* eslint-disable camelcase */
import {
  required, max, min, email, alpha_num, min_value, max_value, integer, numeric,
} from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import VueI18n from 'vue-i18n';
import { FilterDescriptor } from '@/services/filter/filter-descriptor';
import { FilterFieldRule, FilterRuleType } from '@/services/filter/filter-rule';
// eslint-disable-next-line import/extensions
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js/max';
import { validateEmailAddress } from '@/services/email-validation';
import VueLocalizedFormatter from './vue-localized-formatter/src/formatter';

export default (i18n: VueI18n, localized: VueLocalizedFormatter) => {
  extend('min', {
    ...min,
    message: (_, values) => i18n.tc('error.min_length_is_x', undefined, values),
  });
  extend('max', {
    ...max,
    message: (_, values) => i18n.tc('error.max_length_is_x', undefined, values),
  });
  extend('min_value', {
    ...min_value,
    message: (_, values) => i18n.tc('error.min_value_is_x', undefined, values),
  });
  extend('max_value', {
    ...max_value,
    message: (_, values) => i18n.tc('error.max_value_is_x', undefined, values),
  });
  extend('email', {
    ...email,
    message: (_, values) => i18n.tc('error.field_not_valid_email', undefined, values),
  });
  extend('email', email);
  extend('alpha_num', {
    ...alpha_num,
    message: (_, values) => i18n.tc('error.field_not_valid_alphanum', undefined, values),
  });
  extend('integer', {
    ...integer,
    message: (_, values) => i18n.tc('error.field_integer_value', undefined, values),
  });

  extend('required', {
    ...required,
    message: (_, values) => i18n.tc('error.required_field', undefined, values),
  });

  extend('numeric', {
    ...numeric,
    message: (_, values) => i18n.tc('error.field_numeric_value', undefined, values),
  });

  extend('password', {
    params: ['target'],
    validate(value, params) {
      const { target } = params as Record<string, any>;
      return value === target;
    },
    message: (_, values) => i18n.tc('error.password_confirmation_doesnt_match', undefined, values),
  });

  extend('goodpassword', {
    validate(value, _) {
      if (value === undefined) return false;
      const goodPasswordRx = /^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$/;
      return (value.match(goodPasswordRx));
    },
    message: (_, values) => i18n.tc('label.weak_password', undefined, values),
  });

  extend('gteq', {
    params: ['target'],
    validate(value, params) {
      const { target } = params as Record<string, any>;
      if (target === undefined || target === null) {
        return true;
      }
      return value >= target;
    },
    message: (_, values) => i18n.tc('error.field_larger_or_equal_field', undefined, values),
  });

  extend('lteq', {
    params: ['target'],
    validate(value, params) {
      const { target } = params as Record<string, any>;
      if (target === undefined || target === null) {
        return true;
      }
      return value <= target;
    },
    message: (_, values) => i18n.tc('error.field_less_or_equal_field', undefined, values),
  });

  extend('gteqTime', {
    params: ['target'],
    validate(value, params) {
      const { target } = params as Record<string, any>;
      if (target === undefined || target === null) {
        return true;
      }
      return value >= target;
    },
    message: (_, values) => {
      // eslint-disable-next-line no-underscore-dangle
      const formattedTgt = localized.hourMinTextFrom24hr(values._target_);
      const vals = { _target_: formattedTgt };
      return i18n.tc('error.field_larger_or_equal_field', undefined, vals);
    },
  });

  extend('lteqTime', {
    params: ['target'],
    validate(value, params) {
      const { target } = params as Record<string, any>;
      if (target === undefined || target === null) {
        return true;
      }
      return value <= target;
    },
    message: (_, values) => {
      // eslint-disable-next-line no-underscore-dangle
      const formattedTgt = localized.hourMinTextFrom24hr(values._target_);
      const vals = { _target_: formattedTgt };
      return i18n.tc('error.field_less_or_equal_field', undefined, vals);
    },
  });

  extend('gteqDate', {
    params: ['target'],
    validate(value, params) {
      const { target } = params as Record<string, any>;
      if (target === undefined || target === null) {
        return true;
      }
      return value >= target;
    },
    message: (_, values) => {
      // eslint-disable-next-line no-underscore-dangle
      const formattedTgt = localized.fullDateText(values._target_);
      const vals = { _target_: formattedTgt };
      return i18n.tc('error.field_larger_or_equal_field', undefined, vals);
    },
  });

  extend('lteqDate', {
    params: ['target'],
    validate(value, params) {
      const { target } = params as Record<string, any>;
      if (target === undefined || target === null) {
        return true;
      }
      return value <= target;
    },
    message: (_, values) => {
      // eslint-disable-next-line no-underscore-dangle
      const formattedTgt = localized.fullDateText(values._target_);
      const vals = { _target_: formattedTgt };
      return i18n.tc('error.field_less_or_equal_field', undefined, vals);
    },
  });

  extend('cfrownoop', {
    params: ['descriptor'],
    validate(value, params) {
      const { descriptor } = params as Record<string, any>;
      const filterDescriptor = descriptor as FilterDescriptor;
      const rule = value as FilterFieldRule;
      if (!filterDescriptor) return false;
      if (rule.field === '') return true;
      if (rule.type === FilterRuleType.None) return true;
      return filterDescriptor.validateRule(rule, false)?.toString() ?? true;
    },
    // message: (_, values) => {
    //   // eslint-disable-next-line no-underscore-dangle
    //   const formattedTgt = localized.hourMinTextFrom24hr(values._target_);
    //   const vals = { _target_: formattedTgt };
    //   return i18n.tc('error.field_less_or_equal_field', undefined, vals);
    // },
  });

  extend('cfrow', {
    params: ['descriptor'],
    validate(value, params) {
      const { descriptor } = params as Record<string, any>;
      const filterDescriptor = descriptor as FilterDescriptor;
      const rule = value as FilterFieldRule;
      if (!filterDescriptor) return false;
      if (rule.field === '') return true;
      if (rule.type === FilterRuleType.None) return true;
      return filterDescriptor.validateRule(rule)?.toString() ?? true;
    },
    // message: (_, values) => {
    //   // eslint-disable-next-line no-underscore-dangle
    //   const formattedTgt = localized.hourMinTextFrom24hr(values._target_);
    //   const vals = { _target_: formattedTgt };
    //   return i18n.tc('error.field_less_or_equal_field', undefined, vals);
    // },
  });

  extend('validPhone', {
    params: ['cc'],
    validate(value, params) {
      const { cc } = params as Record<string, any>;
      return isValidPhoneNumber(value, cc as CountryCode);
    },
    message: (_, values) => i18n.t('error.phone_number_invalid') as string,
  });

  extend('validEmail', {
    validate(value) { return validateEmailAddress(value); },
    message: (_, values) => i18n.t('error.field_not_valid_email') as string,
  });

  // extend('filterrow',
  //   (value, params) => {
  //     const { descriptor } = params as Record<string, any>;
  //     const filterDescriptor = descriptor as FilterDescriptor;
  //     const rule = value as FilterFieldRule;
  //     if (!filterDescriptor) return false;
  //     return filterDescriptor.validateRule(rule) ?? true;
  //   });

  // extend('intlPhone', {
  //   validate(value, _) {
  //     if (value === undefined) return false;
  //     const phoneRegex = /^\+[1-9]?[0-9]{7,14}$/;
  //     const stripRegex = /[ ().[\]-{}/\\|<>#]+/g;
  //     const stripped = value.replace(stripRegex, '');
  //     return (stripped.match(phoneRegex));
  //   },
  //   message: (_, values) => i18n.tc('error.field_incorrect_phone_number_format', undefined, values),
  // });

  extend('phone', {
    validate(value, _) {
      if (value === undefined) return false;
      const phoneRegex = /^(\+[1-9]{0,3})?[0-9]{4,15}$/;
      const stripRegex = /[ ()\\.-]+/g;
      const stripped = value.replace(stripRegex, '');
      return (stripped.match(phoneRegex));
    },
    message: (_, values) => i18n.tc('error.phone_number_invalid', undefined, values),
  });

  extend('url', {
    validate(value, _) {
      try {
        // eslint-disable-next-line no-new
        new URL(value as string);
      } catch {
        return false;
      }
      return true;
    },
    message: (_, values) => i18n.tc('error.invalid_url', undefined, values),
  });

  extend('amount', {
    validate(value, _) {
      if (!value) return true;
      return value.match(/^\d+[\\.,]?\d?\d?$/);
    },
    message: (_, values) => i18n.tc('error.invalid_amount', undefined, values),
  });

  extend('giftcardNumber', {
    validate(value, _) {
      return value.match(/^([a-zA-Z0-9]{4}-){3}[a-zA-Z0-9]{4}$/);
    },
    message: (_, values) => i18n.tc('error.invalid_giftcard_format', undefined, values),
  });

  extend('minMaxAmount', {
    params: ['minVal', 'maxVal', 'curr', 'div'],
    validate(value, params) {
      if (!value) return true;

      const conv = (num: string|number) => (typeof num === 'string' ? parseFloat(num.replaceAll(',', '.')) : num);

      const {
        minVal, maxVal, div, curr,
      } = params as Record<string, any>;
      const predMin = (num: string|number) => minVal === undefined || conv(num) >= conv(minVal);
      const predMax = (num: string|number) => maxVal === undefined || conv(num) <= conv(maxVal);

      const vals = {
        max: localized.currencyString(maxVal / div, curr),
        min: localized.currencyString(minVal / div, curr),
      };

      if (Array.isArray(value)) {
        if (value.find((num) => !predMin(num))) return i18n.tc('error.min_value_is_x', undefined, vals);
        if (value.find((num) => !predMax(num))) return i18n.tc('error.max_value_is_x', undefined, vals);
      } else {
        if (!predMin(value)) return i18n.tc('error.min_value_is_x', undefined, vals);
        if (!predMax(value)) return i18n.tc('error.max_value_is_x', undefined, vals);
      }

      return true;
    },
  });
};
