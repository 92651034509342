/* eslint-disable vue/max-len */
/* eslint-disable max-len */
import { IAccountSetting } from '@/api/api-update';
import { boolFromValue, numberFromValue } from '@/api/api-utils';

const DefaultSplitLunchDinnerTime = '14:00';
const DefaultSendThankYouAtSpecificTime = '12:00';
const DefaultSendReminderAtSpecificTime = '12:00';
const DefaultDefaultMaxPartySize = 10;
const DefaultDefaultMinPartySize = 1;
const DefaultMinMinutesInAdvance = 1;
const DefaultMinMinutesToModify = 60;
const DefaultMaxTimeForToday = '23:45';
const DefaultMaxDaysInAdvance = 90;
const DefaultAllocateWithEmptySeats = 3;
const DefaultMaxGuestsPerTime = 0;
const DefaultMaxReservationsPerTime = 0;

export interface IDTOAccountSetting extends IAccountSetting {
  isDeleted?: string | boolean, // dummy field to satisfy IApi
}

export default class AccountSetting {
  id: number;

  allocateWithEmptySeats?: number;

  autoDarkMode?: boolean;

  autoDarkModeEnd?: string;

  autoDarkModeStart?: string;

  defaultMaxPartySize?: number;

  defaultMinPartySize?: number;

  employeeNameIsNeeded?: boolean;

  markAlwaysAsRead?: boolean;

  markCancelledAsRead?: boolean;

  maxDaysInAdvance?: number;

  defaultMaxGuestsPerTime?: number;

  defaultMaxReservationsPerTime?: number;

  maxTimeForToday?: string;

  minMinutesInAdvance?: number;

  minMinutesToModify?: number;

  sendReminderAtSpecificTime: string;

  sendReminderDaysInAdvance?: number;

  sendThankYouAtSpecificTime: string;

  sendThankYouDaysAfter?: number;

  showLunchDinner?: boolean;

  splitLunchDinnerTime: string;

  tableAllocation?: boolean;

  reserveWithGoogle?: boolean;

  useSetupBranding?: boolean;

  useSetupHours?: boolean;

  useSetupTables?: boolean;

  useSetupContact?: boolean;

  useSetupWelcome?: boolean;

  enableFeedback?: boolean;

  widgetUseFeedbackBreakdown?: boolean;

  enableWaitlist?: boolean;

  enableReconfirmation?: boolean;

  get order(): number { return this.id; } // dummy field to satisfy IListModel

  constructor(o?: IAccountSetting) {
    this.id = o?.accountSettingsId ?? 0;
    this.allocateWithEmptySeats = numberFromValue(o?.allocateWithEmptySeats) ?? DefaultAllocateWithEmptySeats;
    this.autoDarkMode = boolFromValue(o?.autoDarkMode);
    this.autoDarkModeEnd = o?.autoDarkModeEnd;
    this.autoDarkModeStart = o?.autoDarkModeStart;
    this.defaultMaxPartySize = numberFromValue(o?.defaultMaxPartySize) ?? DefaultDefaultMaxPartySize;
    this.defaultMinPartySize = numberFromValue(o?.defaultMinPartySize) ?? DefaultDefaultMinPartySize;
    this.employeeNameIsNeeded = boolFromValue(o?.employeeNameIsNeeded);
    this.markAlwaysAsRead = boolFromValue(o?.markAlwaysAsRead);
    this.markCancelledAsRead = boolFromValue(o?.markCancelledAsRead);
    this.maxDaysInAdvance = numberFromValue(o?.maxDaysInAdvance) ?? DefaultMaxDaysInAdvance;
    this.defaultMaxGuestsPerTime = numberFromValue(o?.defaultMaxGuestsPerTime) ?? DefaultMaxGuestsPerTime;
    this.defaultMaxReservationsPerTime = numberFromValue(o?.defaultMaxReservationsPerTime) ?? DefaultMaxReservationsPerTime;
    this.maxTimeForToday = o?.maxTimeForToday ?? DefaultMaxTimeForToday;
    this.minMinutesInAdvance = numberFromValue(o?.minMinutesInAdvance) ?? DefaultMinMinutesInAdvance;
    this.minMinutesToModify = numberFromValue(o?.minMinutesToModify) ?? DefaultMinMinutesToModify;
    this.sendReminderAtSpecificTime = o?.sendReminderAtSpecificTime ?? DefaultSendReminderAtSpecificTime;
    this.sendReminderDaysInAdvance = numberFromValue(o?.sendReminderDaysInAdvance);
    this.sendThankYouAtSpecificTime = o?.sendThankYouAtSpecificTime ?? DefaultSendThankYouAtSpecificTime;
    this.sendThankYouDaysAfter = numberFromValue(o?.sendThankYouDaysAfter);
    this.showLunchDinner = boolFromValue(o?.showLunchDinner);
    this.splitLunchDinnerTime = o?.splitLunchDinnerTime ?? DefaultSplitLunchDinnerTime;
    this.tableAllocation = boolFromValue(o?.tableAllocation);
    this.reserveWithGoogle = boolFromValue(o?.reserveWithGoogle);

    this.useSetupBranding = boolFromValue(o?.useSetupBranding);
    this.useSetupHours = boolFromValue(o?.useSetupHours);
    this.useSetupTables = boolFromValue(o?.useSetupTables);
    this.useSetupContact = boolFromValue(o?.useSetupContact);
    this.useSetupWelcome = boolFromValue(o?.useSetupWelcome);

    this.enableFeedback = boolFromValue(o?.enableFeedback);
    this.widgetUseFeedbackBreakdown = boolFromValue(o?.widgetUseFeedbackBreakdown);

    this.enableWaitlist = boolFromValue(o?.enableWaitlist);
    this.enableReconfirmation = boolFromValue(o?.enableReconfirmation);
  }

  toDto(): IDTOAccountSetting {
    const dto: IDTOAccountSetting = {
      ...this,
      accountSettingsId: this.id,
      id: undefined,
    };
    return dto;
  }

  static fromData(data: Pick<AccountSetting, keyof AccountSetting>): AccountSetting {
    const copy = new AccountSetting();
    Object.assign(copy, data);
    return copy;
  }
}
