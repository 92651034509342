/**
 * @fileoverview gRPC-Web generated client stub for grpc.api.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.1
// source: marketing_campaign_manager_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var empty_pb = require('./empty_pb.js')

var marketing_campaign_pb = require('./marketing_campaign_pb.js')

var marketing_campaign_settings_pb = require('./marketing_campaign_settings_pb.js')
const proto = {};
proto.grpc = {};
proto.grpc.api = {};
proto.grpc.api.v1 = require('./marketing_campaign_manager_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.grpc.api.v1.MarketingCampaignManagerServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.grpc.api.v1.MarketingCampaignManagerServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.MarketingCampaignIdRequest,
 *   !proto.grpc.api.v1.MarketingCampaignResponse>}
 */
const methodDescriptor_MarketingCampaignManagerService_getCampaign = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.MarketingCampaignManagerService/getCampaign',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.MarketingCampaignIdRequest,
  proto.grpc.api.v1.MarketingCampaignResponse,
  /**
   * @param {!proto.grpc.api.v1.MarketingCampaignIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.MarketingCampaignResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.MarketingCampaignIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.MarketingCampaignResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.MarketingCampaignResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.MarketingCampaignManagerServiceClient.prototype.getCampaign =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.MarketingCampaignManagerService/getCampaign',
      request,
      metadata || {},
      methodDescriptor_MarketingCampaignManagerService_getCampaign,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.MarketingCampaignIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.MarketingCampaignResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.MarketingCampaignManagerServicePromiseClient.prototype.getCampaign =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.MarketingCampaignManagerService/getCampaign',
      request,
      metadata || {},
      methodDescriptor_MarketingCampaignManagerService_getCampaign);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.MarketingCampaignRequest,
 *   !proto.grpc.api.v1.MarketingCampaignResponse>}
 */
const methodDescriptor_MarketingCampaignManagerService_createCampaign = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.MarketingCampaignManagerService/createCampaign',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.MarketingCampaignRequest,
  proto.grpc.api.v1.MarketingCampaignResponse,
  /**
   * @param {!proto.grpc.api.v1.MarketingCampaignRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.MarketingCampaignResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.MarketingCampaignRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.MarketingCampaignResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.MarketingCampaignResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.MarketingCampaignManagerServiceClient.prototype.createCampaign =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.MarketingCampaignManagerService/createCampaign',
      request,
      metadata || {},
      methodDescriptor_MarketingCampaignManagerService_createCampaign,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.MarketingCampaignRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.MarketingCampaignResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.MarketingCampaignManagerServicePromiseClient.prototype.createCampaign =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.MarketingCampaignManagerService/createCampaign',
      request,
      metadata || {},
      methodDescriptor_MarketingCampaignManagerService_createCampaign);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.MarketingCampaignRequest,
 *   !proto.grpc.api.v1.MarketingCampaignResponse>}
 */
const methodDescriptor_MarketingCampaignManagerService_updateCampaign = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.MarketingCampaignManagerService/updateCampaign',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.MarketingCampaignRequest,
  proto.grpc.api.v1.MarketingCampaignResponse,
  /**
   * @param {!proto.grpc.api.v1.MarketingCampaignRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.MarketingCampaignResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.MarketingCampaignRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.MarketingCampaignResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.MarketingCampaignResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.MarketingCampaignManagerServiceClient.prototype.updateCampaign =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.MarketingCampaignManagerService/updateCampaign',
      request,
      metadata || {},
      methodDescriptor_MarketingCampaignManagerService_updateCampaign,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.MarketingCampaignRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.MarketingCampaignResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.MarketingCampaignManagerServicePromiseClient.prototype.updateCampaign =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.MarketingCampaignManagerService/updateCampaign',
      request,
      metadata || {},
      methodDescriptor_MarketingCampaignManagerService_updateCampaign);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.MarketingCampaignIdRequest,
 *   !proto.grpc.api.v1.MarketingCampaignResponse>}
 */
const methodDescriptor_MarketingCampaignManagerService_deleteCampaign = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.MarketingCampaignManagerService/deleteCampaign',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.MarketingCampaignIdRequest,
  proto.grpc.api.v1.MarketingCampaignResponse,
  /**
   * @param {!proto.grpc.api.v1.MarketingCampaignIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.MarketingCampaignResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.MarketingCampaignIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.MarketingCampaignResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.MarketingCampaignResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.MarketingCampaignManagerServiceClient.prototype.deleteCampaign =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.MarketingCampaignManagerService/deleteCampaign',
      request,
      metadata || {},
      methodDescriptor_MarketingCampaignManagerService_deleteCampaign,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.MarketingCampaignIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.MarketingCampaignResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.MarketingCampaignManagerServicePromiseClient.prototype.deleteCampaign =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.MarketingCampaignManagerService/deleteCampaign',
      request,
      metadata || {},
      methodDescriptor_MarketingCampaignManagerService_deleteCampaign);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.ListCampaignsRequest,
 *   !proto.grpc.api.v1.MarketingCampaignResponse>}
 */
const methodDescriptor_MarketingCampaignManagerService_listCampaigns = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.MarketingCampaignManagerService/listCampaigns',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.grpc.api.v1.ListCampaignsRequest,
  proto.grpc.api.v1.MarketingCampaignResponse,
  /**
   * @param {!proto.grpc.api.v1.ListCampaignsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.MarketingCampaignResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.ListCampaignsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.MarketingCampaignResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.MarketingCampaignManagerServiceClient.prototype.listCampaigns =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.MarketingCampaignManagerService/listCampaigns',
      request,
      metadata || {},
      methodDescriptor_MarketingCampaignManagerService_listCampaigns);
};


/**
 * @param {!proto.grpc.api.v1.ListCampaignsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.MarketingCampaignResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.MarketingCampaignManagerServicePromiseClient.prototype.listCampaigns =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.MarketingCampaignManagerService/listCampaigns',
      request,
      metadata || {},
      methodDescriptor_MarketingCampaignManagerService_listCampaigns);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.MarketingCampaignSendRequest,
 *   !proto.grpc.api.v1.MarketingCampaignResponse>}
 */
const methodDescriptor_MarketingCampaignManagerService_sendCampaign = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.MarketingCampaignManagerService/sendCampaign',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.MarketingCampaignSendRequest,
  proto.grpc.api.v1.MarketingCampaignResponse,
  /**
   * @param {!proto.grpc.api.v1.MarketingCampaignSendRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.MarketingCampaignResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.MarketingCampaignSendRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.MarketingCampaignResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.MarketingCampaignResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.MarketingCampaignManagerServiceClient.prototype.sendCampaign =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.MarketingCampaignManagerService/sendCampaign',
      request,
      metadata || {},
      methodDescriptor_MarketingCampaignManagerService_sendCampaign,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.MarketingCampaignSendRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.MarketingCampaignResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.MarketingCampaignManagerServicePromiseClient.prototype.sendCampaign =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.MarketingCampaignManagerService/sendCampaign',
      request,
      metadata || {},
      methodDescriptor_MarketingCampaignManagerService_sendCampaign);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.MarketingCampaignArchiveRequest,
 *   !proto.grpc.api.v1.MarketingCampaignResponse>}
 */
const methodDescriptor_MarketingCampaignManagerService_archiveCampaign = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.MarketingCampaignManagerService/archiveCampaign',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.MarketingCampaignArchiveRequest,
  proto.grpc.api.v1.MarketingCampaignResponse,
  /**
   * @param {!proto.grpc.api.v1.MarketingCampaignArchiveRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.MarketingCampaignResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.MarketingCampaignArchiveRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.MarketingCampaignResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.MarketingCampaignResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.MarketingCampaignManagerServiceClient.prototype.archiveCampaign =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.MarketingCampaignManagerService/archiveCampaign',
      request,
      metadata || {},
      methodDescriptor_MarketingCampaignManagerService_archiveCampaign,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.MarketingCampaignArchiveRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.MarketingCampaignResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.MarketingCampaignManagerServicePromiseClient.prototype.archiveCampaign =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.MarketingCampaignManagerService/archiveCampaign',
      request,
      metadata || {},
      methodDescriptor_MarketingCampaignManagerService_archiveCampaign);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest,
 *   !proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse>}
 */
const methodDescriptor_MarketingCampaignManagerService_previewContactFilter = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.MarketingCampaignManagerService/previewContactFilter',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest,
  proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse,
  /**
   * @param {!proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.MarketingCampaignManagerServiceClient.prototype.previewContactFilter =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.MarketingCampaignManagerService/previewContactFilter',
      request,
      metadata || {},
      methodDescriptor_MarketingCampaignManagerService_previewContactFilter,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.MarketingCampaignPreviewContactFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.MarketingCampaignPreviewContactFilterResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.MarketingCampaignManagerServicePromiseClient.prototype.previewContactFilter =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.MarketingCampaignManagerService/previewContactFilter',
      request,
      metadata || {},
      methodDescriptor_MarketingCampaignManagerService_previewContactFilter);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.MarketingCampaignTestRequest,
 *   !proto.grpc.api.v1.EmptyResponse>}
 */
const methodDescriptor_MarketingCampaignManagerService_testCampaign = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.MarketingCampaignManagerService/testCampaign',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.MarketingCampaignTestRequest,
  empty_pb.EmptyResponse,
  /**
   * @param {!proto.grpc.api.v1.MarketingCampaignTestRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  empty_pb.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.MarketingCampaignTestRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.MarketingCampaignManagerServiceClient.prototype.testCampaign =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.MarketingCampaignManagerService/testCampaign',
      request,
      metadata || {},
      methodDescriptor_MarketingCampaignManagerService_testCampaign,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.MarketingCampaignTestRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.EmptyResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.MarketingCampaignManagerServicePromiseClient.prototype.testCampaign =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.MarketingCampaignManagerService/testCampaign',
      request,
      metadata || {},
      methodDescriptor_MarketingCampaignManagerService_testCampaign);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.EmptyRequest,
 *   !proto.grpc.api.v1.MarketingCampaignSettingsResponse>}
 */
const methodDescriptor_MarketingCampaignManagerService_getSettings = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.MarketingCampaignManagerService/getSettings',
  grpc.web.MethodType.UNARY,
  empty_pb.EmptyRequest,
  proto.grpc.api.v1.MarketingCampaignSettingsResponse,
  /**
   * @param {!proto.grpc.api.v1.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.MarketingCampaignSettingsResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.MarketingCampaignSettingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.MarketingCampaignSettingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.MarketingCampaignManagerServiceClient.prototype.getSettings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.MarketingCampaignManagerService/getSettings',
      request,
      metadata || {},
      methodDescriptor_MarketingCampaignManagerService_getSettings,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.MarketingCampaignSettingsResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.MarketingCampaignManagerServicePromiseClient.prototype.getSettings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.MarketingCampaignManagerService/getSettings',
      request,
      metadata || {},
      methodDescriptor_MarketingCampaignManagerService_getSettings);
};


module.exports = proto.grpc.api.v1;

