/* eslint-disable max-len */
/* eslint-disable vue/max-len */
import i18n from '@/plugins/i18n';

/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
export class ApiError implements Error {
  code: number = 0;

  name: string = '';

  message: string = '';

  constructor(code: number) {
    this.name = ApiErrorCode[code] ?? ApiErrorCode[0];
    this.code = code;
    this.message = message(code);
  }

  static fromError(error: any) {
    // custom grpc error
    if (error.metadata?.code !== undefined) {
      const errorCode = parseInt(error.metadata.code, 10) as number;
      return new ApiError(errorCode);
    }

    if (error.code !== undefined) {
      const errorCode = error.code + ApiErrorCode.grpc_offset;
      const apiError = new ApiError(errorCode);
      apiError.message = error.message || apiError.message;
      return apiError;
    }

    return new ApiError(ApiErrorCode.unknown);
  }

  get isLogoutError(): boolean {
    return LogoutErrors.includes(this.code);
  }
}

export enum ApiErrorCode {
  unknown = 0,

  no_updates = 200,
  new_token = 201,
  logout = 202,
  registered = 203,
  registration_code_sent = 204,
  full_update = 210,
  incremental_update = 211,
  search = 212,

  username_or_email_not_valid = 1,
  password_not_valid = 2,
  ipaddress_not_allowed = 3,
  user_not_found = 4,
  token_not_valid = 5,
  token_not_found_or_expired_or_already_registered = 6,
  opening_account_failed = 7,
  username_already_exists = 8,
  registrationcode_not_found = 9,
  timestamp_not_valid = 10,
  dateandtime_not_valid = 11,
  full_update_needed = 12,
  user_not_allowed = 13,
  app_update_required = 14,
  web_access_not_allowed = 15,
  x_inapp_purchase_and_upgrade_1 = 16,
  x_inapp_purchase_and_upgrade_2 = 17,
  x_inapp_purchase_and_upgrade_3 = 18,
  x_inapp_purchase_and_upgrade_4 = 19,
  tab_not_found = 20,
  dates_not_valid = 22,
  tabitems_not_found = 24,
  weekday_not_valid = 31,
  weekday_time_not_valid = 32,
  json_not_valid = 41,
  daynote_date_not_valid = 52,
  promo_code_not_valid = 60,
  promo_code_already_exists = 61,

  server_error = 500,

  // our custom errors
  no_token_error = 1010,
  missing_token_error = 1011,

  entity_not_found = 1100,
  json_decoding_error = 1101,

  // api v2 error

  // grpc status codes
  grpc_offset = 10100,
  grpc_ok = 10100,
  canceled = 10101,
  grpc_unknown = 10102,
  invalid_argument = 10103,
  deadline_exceeded = 10104,
  not_found = 10105,
  already_exists = 10106,
  permission_denied = 10107,
  resource_exhausted = 10108,
  failed_precondition = 10109,
  aborted = 10110,
  out_of_range = 10111,
  unimplemented = 10112,
  internal = 10113,
  unavailable = 10114,
  dataloss = 10115,
  unauthenticated = 10116,

  // grpc custom codes

  // contact manager
  contact_upcomming_last_visit = 10207,

  // settings
  subdomain_already_exists = 10211,
  subdomain_invalid = 10212,

  // apps
  stripe_connect_error = 10301,

  // giftcards
  giftcard_not_found = 10401,
  giftcard_expired = 10402,
  giftcard_balance_insufficient = 10403,
  giftcard_redeem_error = 10410,

  // campaign manager
  campaign_manager_allowance_reached = 10501,
  campaign_manager_similar_campaign_found = 10502,

  // last error code
  grpc_last = 12100,
}

const LogoutErrors = [
  ApiErrorCode.token_not_valid,
  ApiErrorCode.token_not_found_or_expired_or_already_registered,
  ApiErrorCode.app_update_required,
];

// export function isSmile(code: ApiErrorCode): boolean {
//   return code >= 200 && code < 300;
// }

// export function isError(code: ApiErrorCode): boolean {
//   return !isSmile(code);
// }

// extra errors
const registered = new Map<number, string>();
registered.set(ApiErrorCode.permission_denied, i18n.tc('code.error.13'));

registered.set(ApiErrorCode.giftcard_not_found, i18n.tc('app.giftcards.error.gift_card_not_found'));
registered.set(ApiErrorCode.giftcard_expired, i18n.tc('app.giftcards.error.gift_card_expired'));
registered.set(ApiErrorCode.giftcard_balance_insufficient, i18n.tc('app.giftcards.error.gift_card_insufficient_balance'));
registered.set(ApiErrorCode.giftcard_redeem_error, i18n.tc('app.giftcards.error.gift_card_redeem_error'));

registered.set(ApiErrorCode.campaign_manager_allowance_reached, i18n.tc('message.campaign_manager_allowance_reached'));

function message(code: ApiErrorCode): string {
  const rvalue = registered.get(code);
  if (rvalue) return rvalue;

  const key = `code.error.${String(code)}`;
  const value = i18n.tc(key);
  if (key !== value) return value;
  return ApiErrorCode[code] ?? 'unknown error';
}
