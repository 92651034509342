/**
 * @fileoverview gRPC-Web generated client stub for grpc.api.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.1
// source: contact_manager_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var contact_pb = require('./contact_pb.js')

var employee_pb = require('./employee_pb.js')

var contact_filter_pb = require('./contact_filter_pb.js')
const proto = {};
proto.grpc = {};
proto.grpc.api = {};
proto.grpc.api.v1 = require('./contact_manager_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.grpc.api.v1.ContactServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.grpc.api.v1.ContactServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.ContactIdRequest,
 *   !proto.grpc.api.v1.ContactResponse>}
 */
const methodDescriptor_ContactService_get = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.ContactService/get',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.ContactIdRequest,
  proto.grpc.api.v1.ContactResponse,
  /**
   * @param {!proto.grpc.api.v1.ContactIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.ContactResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.ContactIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.ContactResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.ContactResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.ContactServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.ContactService/get',
      request,
      metadata || {},
      methodDescriptor_ContactService_get,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.ContactIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.ContactResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.ContactServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.ContactService/get',
      request,
      metadata || {},
      methodDescriptor_ContactService_get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.ContactRequest,
 *   !proto.grpc.api.v1.ContactResponse>}
 */
const methodDescriptor_ContactService_create = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.ContactService/create',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.ContactRequest,
  proto.grpc.api.v1.ContactResponse,
  /**
   * @param {!proto.grpc.api.v1.ContactRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.ContactResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.ContactRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.ContactResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.ContactResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.ContactServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.ContactService/create',
      request,
      metadata || {},
      methodDescriptor_ContactService_create,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.ContactRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.ContactResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.ContactServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.ContactService/create',
      request,
      metadata || {},
      methodDescriptor_ContactService_create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.ContactRequest,
 *   !proto.grpc.api.v1.ContactResponse>}
 */
const methodDescriptor_ContactService_update = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.ContactService/update',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.ContactRequest,
  proto.grpc.api.v1.ContactResponse,
  /**
   * @param {!proto.grpc.api.v1.ContactRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.ContactResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.ContactRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.ContactResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.ContactResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.ContactServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.ContactService/update',
      request,
      metadata || {},
      methodDescriptor_ContactService_update,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.ContactRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.ContactResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.ContactServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.ContactService/update',
      request,
      metadata || {},
      methodDescriptor_ContactService_update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.ContactIdRequest,
 *   !proto.grpc.api.v1.ContactResponse>}
 */
const methodDescriptor_ContactService_delete = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.ContactService/delete',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.ContactIdRequest,
  proto.grpc.api.v1.ContactResponse,
  /**
   * @param {!proto.grpc.api.v1.ContactIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.ContactResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.ContactIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.ContactResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.ContactResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.ContactServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.ContactService/delete',
      request,
      metadata || {},
      methodDescriptor_ContactService_delete,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.ContactIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.ContactResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.ContactServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.ContactService/delete',
      request,
      metadata || {},
      methodDescriptor_ContactService_delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.ContactListRequest,
 *   !proto.grpc.api.v1.ContactListResponse>}
 */
const methodDescriptor_ContactService_list = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.ContactService/list',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.grpc.api.v1.ContactListRequest,
  proto.grpc.api.v1.ContactListResponse,
  /**
   * @param {!proto.grpc.api.v1.ContactListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.ContactListResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.ContactListRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.ContactListResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.ContactServiceClient.prototype.list =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.ContactService/list',
      request,
      metadata || {},
      methodDescriptor_ContactService_list);
};


/**
 * @param {!proto.grpc.api.v1.ContactListRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.ContactListResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.ContactServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.ContactService/list',
      request,
      metadata || {},
      methodDescriptor_ContactService_list);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.grpc.api.v1.ContactFilterServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.grpc.api.v1.ContactFilterServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.ContactFilterIdRequest,
 *   !proto.grpc.api.v1.ContactFilterResponse>}
 */
const methodDescriptor_ContactFilterService_get = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.ContactFilterService/get',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.ContactFilterIdRequest,
  proto.grpc.api.v1.ContactFilterResponse,
  /**
   * @param {!proto.grpc.api.v1.ContactFilterIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.ContactFilterResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.ContactFilterIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.ContactFilterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.ContactFilterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.ContactFilterServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.ContactFilterService/get',
      request,
      metadata || {},
      methodDescriptor_ContactFilterService_get,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.ContactFilterIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.ContactFilterResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.ContactFilterServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.ContactFilterService/get',
      request,
      metadata || {},
      methodDescriptor_ContactFilterService_get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.ContactFilterRequest,
 *   !proto.grpc.api.v1.ContactFilterResponse>}
 */
const methodDescriptor_ContactFilterService_create = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.ContactFilterService/create',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.ContactFilterRequest,
  proto.grpc.api.v1.ContactFilterResponse,
  /**
   * @param {!proto.grpc.api.v1.ContactFilterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.ContactFilterResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.ContactFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.ContactFilterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.ContactFilterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.ContactFilterServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.ContactFilterService/create',
      request,
      metadata || {},
      methodDescriptor_ContactFilterService_create,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.ContactFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.ContactFilterResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.ContactFilterServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.ContactFilterService/create',
      request,
      metadata || {},
      methodDescriptor_ContactFilterService_create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.ContactFilterRequest,
 *   !proto.grpc.api.v1.ContactFilterResponse>}
 */
const methodDescriptor_ContactFilterService_update = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.ContactFilterService/update',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.ContactFilterRequest,
  proto.grpc.api.v1.ContactFilterResponse,
  /**
   * @param {!proto.grpc.api.v1.ContactFilterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.ContactFilterResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.ContactFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.ContactFilterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.ContactFilterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.ContactFilterServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.ContactFilterService/update',
      request,
      metadata || {},
      methodDescriptor_ContactFilterService_update,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.ContactFilterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.ContactFilterResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.ContactFilterServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.ContactFilterService/update',
      request,
      metadata || {},
      methodDescriptor_ContactFilterService_update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.ContactFilterIdRequest,
 *   !proto.grpc.api.v1.ContactFilterResponse>}
 */
const methodDescriptor_ContactFilterService_delete = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.ContactFilterService/delete',
  grpc.web.MethodType.UNARY,
  proto.grpc.api.v1.ContactFilterIdRequest,
  proto.grpc.api.v1.ContactFilterResponse,
  /**
   * @param {!proto.grpc.api.v1.ContactFilterIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.ContactFilterResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.ContactFilterIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.grpc.api.v1.ContactFilterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.ContactFilterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.ContactFilterServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/grpc.api.v1.ContactFilterService/delete',
      request,
      metadata || {},
      methodDescriptor_ContactFilterService_delete,
      callback);
};


/**
 * @param {!proto.grpc.api.v1.ContactFilterIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.grpc.api.v1.ContactFilterResponse>}
 *     Promise that resolves to the response
 */
proto.grpc.api.v1.ContactFilterServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/grpc.api.v1.ContactFilterService/delete',
      request,
      metadata || {},
      methodDescriptor_ContactFilterService_delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.ContactFilterListRequest,
 *   !proto.grpc.api.v1.ContactFilterListResponse>}
 */
const methodDescriptor_ContactFilterService_list = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.ContactFilterService/list',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.grpc.api.v1.ContactFilterListRequest,
  proto.grpc.api.v1.ContactFilterListResponse,
  /**
   * @param {!proto.grpc.api.v1.ContactFilterListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.ContactFilterListResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.ContactFilterListRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.ContactFilterListResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.ContactFilterServiceClient.prototype.list =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.ContactFilterService/list',
      request,
      metadata || {},
      methodDescriptor_ContactFilterService_list);
};


/**
 * @param {!proto.grpc.api.v1.ContactFilterListRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.ContactFilterListResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.ContactFilterServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.ContactFilterService/list',
      request,
      metadata || {},
      methodDescriptor_ContactFilterService_list);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.grpc.api.v1.ContactManagerServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.grpc.api.v1.ContactManagerServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.SelectedContactsRequest,
 *   !proto.grpc.api.v1.ContactListResponse>}
 */
const methodDescriptor_ContactManagerService_selectedContacts = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.ContactManagerService/selectedContacts',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.grpc.api.v1.SelectedContactsRequest,
  proto.grpc.api.v1.ContactListResponse,
  /**
   * @param {!proto.grpc.api.v1.SelectedContactsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.ContactListResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.SelectedContactsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.ContactListResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.ContactManagerServiceClient.prototype.selectedContacts =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.ContactManagerService/selectedContacts',
      request,
      metadata || {},
      methodDescriptor_ContactManagerService_selectedContacts);
};


/**
 * @param {!proto.grpc.api.v1.SelectedContactsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.ContactListResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.ContactManagerServicePromiseClient.prototype.selectedContacts =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.ContactManagerService/selectedContacts',
      request,
      metadata || {},
      methodDescriptor_ContactManagerService_selectedContacts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.MergeContactsRequest,
 *   !proto.grpc.api.v1.ContactListResponse>}
 */
const methodDescriptor_ContactManagerService_mergeContacts = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.ContactManagerService/mergeContacts',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.grpc.api.v1.MergeContactsRequest,
  proto.grpc.api.v1.ContactListResponse,
  /**
   * @param {!proto.grpc.api.v1.MergeContactsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.ContactListResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.MergeContactsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.ContactListResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.ContactManagerServiceClient.prototype.mergeContacts =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.ContactManagerService/mergeContacts',
      request,
      metadata || {},
      methodDescriptor_ContactManagerService_mergeContacts);
};


/**
 * @param {!proto.grpc.api.v1.MergeContactsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.ContactListResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.ContactManagerServicePromiseClient.prototype.mergeContacts =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.ContactManagerService/mergeContacts',
      request,
      metadata || {},
      methodDescriptor_ContactManagerService_mergeContacts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.DeleteContactsRequest,
 *   !proto.grpc.api.v1.ContactListResponse>}
 */
const methodDescriptor_ContactManagerService_deleteContacts = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.ContactManagerService/deleteContacts',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.grpc.api.v1.DeleteContactsRequest,
  proto.grpc.api.v1.ContactListResponse,
  /**
   * @param {!proto.grpc.api.v1.DeleteContactsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.ContactListResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.DeleteContactsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.ContactListResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.ContactManagerServiceClient.prototype.deleteContacts =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.ContactManagerService/deleteContacts',
      request,
      metadata || {},
      methodDescriptor_ContactManagerService_deleteContacts);
};


/**
 * @param {!proto.grpc.api.v1.DeleteContactsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.ContactListResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.ContactManagerServicePromiseClient.prototype.deleteContacts =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.ContactManagerService/deleteContacts',
      request,
      metadata || {},
      methodDescriptor_ContactManagerService_deleteContacts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.grpc.api.v1.SearchContactsRequest,
 *   !proto.grpc.api.v1.ContactListResponse>}
 */
const methodDescriptor_ContactManagerService_searchContacts = new grpc.web.MethodDescriptor(
  '/grpc.api.v1.ContactManagerService/searchContacts',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.grpc.api.v1.SearchContactsRequest,
  proto.grpc.api.v1.ContactListResponse,
  /**
   * @param {!proto.grpc.api.v1.SearchContactsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.grpc.api.v1.ContactListResponse.deserializeBinary
);


/**
 * @param {!proto.grpc.api.v1.SearchContactsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.ContactListResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.ContactManagerServiceClient.prototype.searchContacts =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.ContactManagerService/searchContacts',
      request,
      metadata || {},
      methodDescriptor_ContactManagerService_searchContacts);
};


/**
 * @param {!proto.grpc.api.v1.SearchContactsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.grpc.api.v1.ContactListResponse>}
 *     The XHR Node Readable Stream
 */
proto.grpc.api.v1.ContactManagerServicePromiseClient.prototype.searchContacts =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/grpc.api.v1.ContactManagerService/searchContacts',
      request,
      metadata || {},
      methodDescriptor_ContactManagerService_searchContacts);
};


module.exports = proto.grpc.api.v1;

