import { MarketingCampaignStatus } from '@/grpc-api/model/marketing-campaign';

// eslint-disable-next-line import/prefer-default-export
export const colorForCampaignStatus = (status: MarketingCampaignStatus): string => {
  switch (status) {
    case MarketingCampaignStatus.Draft:
      return 'yellow';
    case MarketingCampaignStatus.Scheduled:
    case MarketingCampaignStatus.Processing:
      return 'orange';
    case MarketingCampaignStatus.Sent:
      return 'success';
    case MarketingCampaignStatus.Abandoned:
      return 'gray';
    case MarketingCampaignStatus.Failure:
      return 'error';
    case MarketingCampaignStatus.None:
    default:
      return '';
  }
};
