export default function nameWithCopyText(text: string, copyText: string = 'copy'): string {
  const trimmed = text.trim();

  const regexp = new RegExp(`^(.*)(\\s+\\(${copyText}(?:\\s+(\\d+))?\\))$`, 'i');
  const matches = trimmed.match(regexp);
  if (matches) {
    // copy text found: (copy) or (copy N)
    const [, name, iscopy, copyNumber] = matches;
    let number = 1;

    if (copyNumber) {
      // nomber found: (copy N)
      number = parseInt(copyNumber, 10) + 1;
    }

    return `${name} (${copyText} ${number})`;
  }

  return `${trimmed} (${copyText})`;
}
